import { Icon, IconProps } from '@chakra-ui/react';

export const MotleyFool = (props: IconProps) => {
  return (
    <Icon width="130" height="36" viewBox="0 0 130 36" fill="none" {...props}>
      <path
        d="M3.91322 22.2881H0.699463V19.8635H9.8051V22.2881H6.59135V31.8963H3.91322V22.2881Z"
        fill="#4D4D4D"
      />
      <path
        d="M10.5196 19.8635H13.0192V24.2636C13.3763 23.5452 14.269 23.0064 15.4295 23.0064C17.4827 23.0064 18.6433 24.3534 18.6433 26.5983V31.8963H16.0544V26.8677C16.0544 25.8799 15.5188 25.2514 14.5368 25.2514C13.6441 25.2514 12.9299 25.9697 12.9299 26.9575V31.8963H10.3411V19.8635H10.5196Z"
        fill="#4D4D4D"
      />
      <path
        d="M25.9634 26.598C25.9634 25.7898 25.16 25.1612 24.178 25.1612C23.1067 25.1612 22.214 25.7898 22.1247 26.598H25.9634ZM19.5359 27.5858C19.5359 24.8021 21.4999 23.0061 24.178 23.0061C26.8561 23.0061 28.5523 24.9816 28.5523 27.4062C28.5523 27.4062 28.5523 27.8551 28.463 28.2143H22.1247C22.214 29.2919 23.0175 29.9205 24.4458 29.9205C26.1419 29.9205 27.0346 29.3817 27.5703 29.1123V31.3572C26.7668 31.896 25.8741 32.1654 24.3565 32.1654C21.4999 32.1654 19.5359 30.3695 19.5359 27.5858Z"
        fill="#4D4D4D"
      />
      <path
        d="M33.5513 19.4143L39.8895 26.3287L46.2277 19.4143V31.8961H43.6389V25.7899L39.8895 30.1002L36.1401 25.7899V31.8961H33.5513V19.4143Z"
        fill="#4D4D4D"
      />
      <path
        d="M51.941 29.8306C53.1908 29.8306 54.1728 28.8429 54.1728 27.5857C54.1728 26.3286 53.1908 25.3408 51.941 25.3408C50.6912 25.3408 49.7092 26.3286 49.7092 27.5857C49.7092 28.8429 50.6912 29.8306 51.941 29.8306ZM51.941 22.9163C54.6191 22.9163 56.7616 24.9816 56.7616 27.5857C56.7616 30.1898 54.6191 32.2552 51.941 32.2552C49.2629 32.2552 47.1204 30.1898 47.1204 27.5857C47.1204 24.9816 49.2629 22.9163 51.941 22.9163Z"
        fill="#4D4D4D"
      />
      <path
        d="M58.1904 25.5207H57.0299V23.186H58.1904V19.8635H60.69V23.186H62.5647V25.5207H60.69V31.8963H58.1904V25.5207Z"
        fill="#4D4D4D"
      />
      <path
        d="M65.8671 19.8635H63.2782V31.8963H65.8671V19.8635Z"
        fill="#4D4D4D"
      />
      <path
        d="M73.0983 26.598C73.0983 25.7898 72.2948 25.1612 71.3129 25.1612C70.2416 25.1612 69.3489 25.7898 69.2596 26.598H73.0983ZM66.6708 27.5858C66.6708 24.8021 68.6347 23.0061 71.3129 23.0061C73.991 23.0061 75.6871 24.9816 75.6871 27.4062C75.6871 27.4062 75.6871 27.8551 75.5979 28.2143H69.2596C69.3489 29.2919 70.1523 29.9205 71.5807 29.9205C73.2768 29.9205 74.1695 29.3817 74.7052 29.1123V31.3572C73.9017 31.896 73.009 32.1654 71.4914 32.1654C68.6347 32.1654 66.6708 30.3695 66.6708 27.5858Z"
        fill="#4D4D4D"
      />
      <path
        d="M75.33 23.1855H78.0081L80.3291 29.0224L82.6502 23.1855H85.3283L80.2399 35.3082H77.5617L78.9901 32.0755L75.33 23.1855Z"
        fill="#4D4D4D"
      />
      <path
        d="M89.6151 19.8635H97.5602V22.2881H92.2932V24.8024H97.1139V27.1371H92.2932V31.8963H89.6151V19.8635Z"
        fill="#4D4D4D"
      />
      <path
        d="M102.736 29.8306C103.986 29.8306 104.968 28.8429 104.968 27.5857C104.968 26.3286 103.986 25.3408 102.736 25.3408C101.486 25.3408 100.504 26.3286 100.504 27.5857C100.504 28.8429 101.486 29.8306 102.736 29.8306ZM102.736 22.9163C105.414 22.9163 107.557 24.9816 107.557 27.5857C107.557 30.1898 105.414 32.2552 102.736 32.2552C100.058 32.2552 97.9154 30.1898 97.9154 27.5857C97.9154 24.9816 100.058 22.9163 102.736 22.9163Z"
        fill="#4D4D4D"
      />
      <path
        d="M112.914 29.8306C114.163 29.8306 115.145 28.8429 115.145 27.5857C115.145 26.3286 114.163 25.3408 112.914 25.3408C111.664 25.3408 110.682 26.3286 110.682 27.5857C110.682 28.8429 111.664 29.8306 112.914 29.8306ZM112.914 22.9163C115.592 22.9163 117.734 24.9816 117.734 27.5857C117.734 30.1898 115.592 32.2552 112.914 32.2552C110.236 32.2552 108.093 30.1898 108.093 27.5857C108.093 24.9816 110.236 22.9163 112.914 22.9163Z"
        fill="#4D4D4D"
      />
      <path
        d="M121.215 19.8635H118.626V31.8963H121.215V19.8635Z"
        fill="#4D4D4D"
      />
      <path
        d="M129.251 16.8101C129.251 17.7979 128.447 18.5163 127.554 18.5163C126.572 18.5163 125.858 17.7081 125.858 16.8101C125.858 15.9122 126.662 15.104 127.554 15.104C128.447 15.0142 129.251 15.8224 129.251 16.8101Z"
        fill="#4D4D4D"
      />
      <path
        d="M117.109 0.0175781C122.108 0.0175781 126.393 5.6748 127.553 13.3973C127.553 13.5769 127.464 13.6667 127.375 13.6667C127.286 13.6667 127.107 13.5769 127.107 13.4871C125.947 10.5238 123.893 8.6381 121.572 8.6381C118.269 8.6381 115.323 12.7688 114.966 18.426C114.52 19.324 111.038 20.0423 107.914 20.2219V6.66256C109.789 2.6217 113.716 0.0175781 117.109 0.0175781Z"
        fill="#4D4D4D"
      />
      <path
        d="M86.3998 16.8101C86.3998 17.7979 87.2032 18.5163 88.0959 18.5163C89.0779 18.5163 89.7921 17.7081 89.7921 16.8101C89.7921 15.9122 88.9886 15.104 88.0959 15.104C87.2032 15.0142 86.3998 15.8224 86.3998 16.8101Z"
        fill="#4D4D4D"
      />
      <path
        d="M98.5422 0.0175781C93.5431 0.0175781 89.2581 5.6748 88.0975 13.3973C88.0975 13.5769 88.1868 13.6667 88.2761 13.6667C88.3654 13.6667 88.5439 13.5769 88.5439 13.4871C89.7044 10.5238 91.7576 8.6381 94.0787 8.6381C97.3817 8.6381 100.328 12.7688 100.685 18.426C101.131 19.3239 104.613 20.0423 107.737 20.2219V6.66256C105.862 2.62169 101.935 0.0175781 98.5422 0.0175781Z"
        fill="#4D4D4D"
      />
      <path
        d="M123.626 31.357C123.715 31.357 123.804 31.2672 123.804 31.1774C123.804 31.0876 123.715 30.9978 123.626 30.9978H123.447V31.2672H123.626V31.357ZM123.269 30.8182H123.626C123.804 30.8182 123.894 30.908 123.894 31.0876C123.894 31.1774 123.804 31.2672 123.715 31.357L123.983 31.7162H123.804L123.626 31.357H123.536V31.806H123.358V30.8182H123.269ZM124.251 31.357C124.251 30.908 123.983 30.6386 123.536 30.6386C123.179 30.6386 122.822 30.908 122.822 31.357C122.822 31.7162 123.09 32.0754 123.536 32.0754C123.983 32.0754 124.251 31.7162 124.251 31.357ZM122.733 31.357C122.733 30.908 123.09 30.5488 123.536 30.5488C123.983 30.5488 124.429 30.908 124.429 31.357C124.429 31.806 124.072 32.1652 123.536 32.1652C123.09 32.1652 122.733 31.806 122.733 31.357Z"
        fill="#4D4D4D"
      />
    </Icon>
  );
};
