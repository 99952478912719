/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  IGetUsOfferingsQuery,
  IGetUsOfferingsQueryVariables,
  GetUsOfferingsDocument,
} from './types';
import { IOfferingConnection } from '@r-client/data/graphql-types';
export type { IOfferingConnection };

export type IGetUsOfferingsQueryOpts =
  IQueryModelOpts<IGetUsOfferingsQueryVariables>;

export class GetUsOfferingsQuery extends BaseQueryModel<
  IGetUsOfferingsQuery,
  IGetUsOfferingsQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGetUsOfferingsQueryOpts) {
    super(opts);
  }

  get query() {
    return GetUsOfferingsDocument;
  }
}
export function useGetUsOfferingsQuery(
  opts?: Omit<IGetUsOfferingsQueryOpts, 'client'>
) {
  const client = useGqlClient();
  return useQuery(
    () => new GetUsOfferingsQuery({ ...opts, client }),
    opts?.variables
  );
}
