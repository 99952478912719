import { Box, SimpleGrid } from '@chakra-ui/react';

import {
  CarouselCard,
  ICarouselCardItem,
} from '@r-client/shared/feature/carousel';

import noteBG from './assets/note-bg.jpg';
import noteLogo from './assets/note-logo.png';
import walletBG from './assets/wallet-bg.jpg';
import walletLogo from './assets/wallet-logo.png';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IGlobalFocusCardsProps {}

const NOTE_CARD: ICarouselCardItem = {
  id: 'note',
  title: 'Republic Note',
  overlayTheme: 'dark',
  link: '/note',
  logo: noteLogo,
  logoAltText: 'Republic Note',
  description:
    'Our digital security linked to Republic’s ever-green portfolio success',
  backgroundImageDesktop: noteBG,
};

const WALLET_CARD: ICarouselCardItem = {
  id: 'wallet',
  title: 'Republic Wallet',
  overlayTheme: 'dark',
  link: '/wallet',
  logo: walletLogo,
  logoAltText: 'Republic Wallet',
  description: 'Our secure, multi-chain wallet tailored for tokenized assets',
  backgroundImageDesktop: walletBG,
};

export const GlobalFocusCards = (_props: IGlobalFocusCardsProps) => {
  return (
    <Box bg="grey.800" py="20" px="4">
      <SimpleGrid maxWidth="1152px" mx="auto" columns={[1, 2]} spacing="9">
        <CarouselCard item={NOTE_CARD} w="100%" h="260px" />
        <CarouselCard item={WALLET_CARD} w="100%" h="260px" />
      </SimpleGrid>
    </Box>
  );
};
