import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';

import { Section } from './section';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IGlobalRegulationsBenefitsProps {}

export const GlobalRegulationsBenefits = (
  _props: IGlobalRegulationsBenefitsProps
) => {
  return (
    <Container maxWidth="1152px" py="20">
      <Heading size="republic-h4" mt="0" mb="2" lineHeight="32px">
        Regulated, registered, and readily available
      </Heading>
      <Text
        textStyle="md"
        color="grey.500"
        maxW="724px"
        mb={{ base: 7, md: 14 }}
      >
        Republic operates globally through our many licenses, partnerships, and
        affiliates that allows us to offer:
      </Text>

      <SimpleGrid columns={[1, 3]} spacing={{ base: 0, md: 8 }}>
        <Section
          heading="FINRA Registered US funding portal"
          body="Qualified to conduct Regulation Crowdfunding offerings for
            non-accredited and accredited investors alike in the United States."
        />
        <Section
          heading="Node & Market infrastructure"
          body="Qualified to conduct an institutional investors and qualified investors while engaging in broker-dealer services and exchange services including virtual-asset derivatives trading."
        />
        <Section
          heading="State Money Transmitter Licenses"
          body={
            <>
              <Text mb="1">
                Qualified to conduct the comprehensive services for obtaining a
                Money Transmitter License in 50 U.S. states and D.C.
              </Text>
              <Text>
                <InfoOutlineIcon mr="1" color="grey.200" />
                Montana state isn't included
              </Text>
            </>
          }
        />
        <Section
          heading="FINRA Licensed Broker-Dealer"
          body="Qualified to conduct Regulation A+, Regulation D, and Regulation S offerings for accredited investors."
        />
        <Section
          heading="European licenses"
          body="UK and EU registered by the FCA and CBI to offer assets to retail investors. In the UK, we are additionally licensed to hold client assets."
        />
        <Section
          heading="FINRA Registered MSB"
          body="Qualified to conduct a funds transfer and is a Money Services Business on a regular basis."
        />
        <Section
          heading="SEC Registered Transfer Agent"
          body="Qualified advisory and management firm for institutional investors."
        />
        <Section
          heading="SEC Registered ATS"
          body="Qualified trading venue for security buy and sell orders."
        />
      </SimpleGrid>
    </Container>
  );
};
