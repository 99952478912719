/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import * as Types from '@r-client/data/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ICarouselBannerContentsFragment = {
  __typename?: 'BannerContent';
  id: string;
  isActive?: boolean | null | undefined;
  title?: string | null | undefined;
  priority?: number | null | undefined;
  theme?: string | null | undefined;
  overlayTheme: string;
  overlayColor?: string | null | undefined;
  overlayOpacity: string;
  link?: string | null | undefined;
  logo?: string | null | undefined;
  logoAltText?: string | null | undefined;
  description?: string | null | undefined;
  backgroundImageDesktop?: string | null | undefined;
  backgroundImageAltText?: string | null | undefined;
  backgroundImageMobile?: string | null | undefined;
  foregroundImageDesktop?: string | null | undefined;
  foregroundImageMobile?: string | null | undefined;
  ctaText?: string | null | undefined;
  ctaTheme?: string | null | undefined;
  pillText?: string | null | undefined;
  openLinkInNewTab?: boolean | null | undefined;
  termsAndConditions?: string | null | undefined;
};

export type ICarouselContentsFragment = {
  __typename?: 'Carousel';
  id: string;
  jurisdiction: string;
  settings?: Record<string, unknown> | null | undefined;
  bannerContents: Array<{
    __typename?: 'BannerContent';
    id: string;
    isActive?: boolean | null | undefined;
    title?: string | null | undefined;
    priority?: number | null | undefined;
    theme?: string | null | undefined;
    overlayTheme: string;
    overlayColor?: string | null | undefined;
    overlayOpacity: string;
    link?: string | null | undefined;
    logo?: string | null | undefined;
    logoAltText?: string | null | undefined;
    description?: string | null | undefined;
    backgroundImageDesktop?: string | null | undefined;
    backgroundImageAltText?: string | null | undefined;
    backgroundImageMobile?: string | null | undefined;
    foregroundImageDesktop?: string | null | undefined;
    foregroundImageMobile?: string | null | undefined;
    ctaText?: string | null | undefined;
    ctaTheme?: string | null | undefined;
    pillText?: string | null | undefined;
    openLinkInNewTab?: boolean | null | undefined;
    termsAndConditions?: string | null | undefined;
  }>;
};

export type ICarouselsQueryVariables = Types.Exact<{
  slug?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type ICarouselsQuery = {
  __typename?: 'Query';
  carousels: {
    __typename?: 'CarouselConnection';
    nodes?:
      | Array<
          | {
              __typename?: 'Carousel';
              id: string;
              jurisdiction: string;
              settings?: Record<string, unknown> | null | undefined;
              bannerContents: Array<{
                __typename?: 'BannerContent';
                id: string;
                isActive?: boolean | null | undefined;
                title?: string | null | undefined;
                priority?: number | null | undefined;
                theme?: string | null | undefined;
                overlayTheme: string;
                overlayColor?: string | null | undefined;
                overlayOpacity: string;
                link?: string | null | undefined;
                logo?: string | null | undefined;
                logoAltText?: string | null | undefined;
                description?: string | null | undefined;
                backgroundImageDesktop?: string | null | undefined;
                backgroundImageAltText?: string | null | undefined;
                backgroundImageMobile?: string | null | undefined;
                foregroundImageDesktop?: string | null | undefined;
                foregroundImageMobile?: string | null | undefined;
                ctaText?: string | null | undefined;
                ctaTheme?: string | null | undefined;
                pillText?: string | null | undefined;
                openLinkInNewTab?: boolean | null | undefined;
                termsAndConditions?: string | null | undefined;
              }>;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export const CarouselBannerContentsFragmentDoc = gql`
  fragment CarouselBannerContents on BannerContent {
    id
    isActive
    title
    priority
    theme
    overlayTheme
    overlayColor
    overlayOpacity
    link
    logo
    logoAltText
    description
    backgroundImageDesktop
    backgroundImageAltText
    backgroundImageMobile
    foregroundImageDesktop
    foregroundImageMobile
    ctaText
    ctaTheme
    pillText
    openLinkInNewTab
    termsAndConditions
  }
`;
export const CarouselContentsFragmentDoc = gql`
  fragment CarouselContents on Carousel {
    id
    jurisdiction
    settings
    bannerContents {
      ...CarouselBannerContents
    }
  }
  ${CarouselBannerContentsFragmentDoc}
`;
export const CarouselsDocument = gql`
  query Carousels($slug: String) {
    carousels(slug: $slug) {
      nodes {
        ...CarouselContents
      }
    }
  }
  ${CarouselContentsFragmentDoc}
`;
export type CarouselsQueryResult = Apollo.QueryResult<
  ICarouselsQuery,
  ICarouselsQueryVariables
>;
