import { BigNumber } from 'bignumber.js';

import {
  createFormatter,
  FormatterFloatDigitsBigNumber,
} from './intl-formatter';

type IAmountWithLetters<T extends number | BigNumber> = {
  newAmount: T;
  letter: string;
  formatted: string;
};

export const formatAmount = (
  amount = 0,
  round = true,
  decimalPlaces: number | 'auto' = 'auto'
) => {
  const defaultDecimalPlaces = round ? 0 : 2;
  decimalPlaces =
    decimalPlaces === 'auto' ? defaultDecimalPlaces : decimalPlaces;

  return createFormatter(false, round, decimalPlaces).format(amount);
};

export const formatAmountWithLetters = <T extends number | BigNumber>(
  amount: T,
  round: boolean | 'auto' = 'auto',
  decimalPlaces = 2
): IAmountWithLetters<T> => {
  let letter = '';
  let newAmount = amount;

  if (amount instanceof BigNumber) {
    if (amount.div(1000000).gte(1)) {
      newAmount = amount.div(1000000) as T;
      letter = 'M';
    } else if (amount.div(1000).gte(1)) {
      newAmount = amount.div(1000) as T;
      letter = 'K';
    }

    if (round && newAmount instanceof BigNumber) {
      newAmount = new BigNumber(
        newAmount.toFormat(round === 'auto' ? decimalPlaces : 0)
      ) as T;
    }
  } else if (typeof amount === 'number') {
    if (amount / 1000000 >= 1) {
      newAmount = (amount / 1000000) as T;
      letter = 'M';
    } else if (amount / 1000 >= 1) {
      newAmount = (amount / 1000) as T;
      letter = 'K';
    }

    if (round && typeof newAmount === 'number') {
      newAmount = Number(
        round === 'auto'
          ? createFormatter(false, false, decimalPlaces).format(newAmount)
          : createFormatter(false, true).format(newAmount)
      ) as T;
    }
  }

  return {
    newAmount,
    letter,
    formatted: `${newAmount}${letter}`,
  };
};

export const formatDecimalPercent = <T extends number | BigNumber>(
  value?: T,
  withSymbol = true,
  decimalPlaces = 2
): string | T => {
  let percent: T;

  if (value instanceof BigNumber) {
    percent = new BigNumber(
      value.multipliedBy(100).toFormat(decimalPlaces, {
        ...FormatterFloatDigitsBigNumber,
        groupSeparator: '',
      })
    ) as T;
  } else if (typeof value === 'number') {
    percent = Number(
      createFormatter(false, false, decimalPlaces, false).format(value * 100)
    ) as T;
  } else {
    return '';
  }

  const isPositive =
    value instanceof BigNumber
      ? value.isPositive() && !value.isZero()
      : value > 0;

  return withSymbol ? `${isPositive ? '+' : ''}${percent}%` : percent;
};
