import React, { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../../tokens/theme';
import { ICarousel } from '../../types';
import { comparePriority } from '../../utils/compare-priority';
import { Carousel } from './carousel';
import { CarouselItem } from './carousel-item';
import { CarouselPlaceholderBg, ReserveCarouselSpace } from './style';
import { CTASize } from './types';

interface IHeroCarouselProps {
  autoplaySpeed?: number;
  carousel: ICarousel | undefined | null;
  desktopHeight?: string;
  centeredContent?: boolean;
  dotsOverBanner?: boolean;
  ctaSize?: CTASize;
  noCta?: boolean;
  carouselItemSeen: (itemId: string) => void;
  carouselItemCTAClicked: (itemId: string) => void;
  dataAutomationId?: string;
  isLoading?: boolean;
}

export const HeroCarousel = observer(function HeroCarousel({
  autoplaySpeed,
  carousel,
  desktopHeight,
  centeredContent,
  dotsOverBanner,
  ctaSize,
  noCta = false,
  carouselItemSeen,
  carouselItemCTAClicked,
  dataAutomationId,
  isLoading,
}: IHeroCarouselProps) {
  const isNotReady = !carousel || isLoading;

  const banners = useMemo(() => {
    if (!carousel?.bannerContents) {
      return [];
    }
    return [...carousel.bannerContents].sort(comparePriority);
  }, [carousel?.bannerContents]);

  if (
    !isNotReady &&
    (!carousel.bannerContents || carousel.bannerContents.length === 0)
  ) {
    return null;
  }

  return (
    <ThemeProvider theme={getTheme('investor')}>
      <CarouselPlaceholderBg>
        <ReserveCarouselSpace desktop={500} mobile={560} isLoading={isNotReady}>
          {!isNotReady && (
            <Carousel
              isHomepageHeader
              dotsOverBanner={dotsOverBanner}
              dataAutomationId={dataAutomationId}
              autoplaySpeed={autoplaySpeed}
              autoplay={
                carousel?.settings?.['data-auto-play'] === 'true' &&
                carousel.bannerContents.length > 1
              }
              pauseOnHover={
                carousel?.settings?.['data-stop-on-hover'] === 'true'
              }
            >
              {banners.map((item, i) => {
                return (
                  <CarouselItem
                    centeredContent={centeredContent}
                    desktopHeight={desktopHeight}
                    position={i + 1}
                    itemId={item.id}
                    theme={item.theme}
                    backgroundDesktopImage={item.backgroundImageDesktop}
                    backgroundMobileImage={item.backgroundImageMobile}
                    foregroundDesktopImage={item.foregroundImageDesktop}
                    foregroundMobileImage={item.foregroundImageMobile}
                    logo={item.logo}
                    overlayTheme={item.overlayTheme}
                    overlayColor={item.overlayColor}
                    overlayOpacity={parseInt(item.overlayOpacity, 10) / 100}
                    ctaText={noCta ? undefined : item.ctaText}
                    ctaTheme={noCta ? undefined : item.ctaTheme}
                    description={item.description}
                    link={item.link}
                    linkNewTab={item.openLinkInNewTab}
                    pillText={item.pillText}
                    title={item.title}
                    disclaimer={item.termsAndConditions}
                    logoAlt={item.logoAltText}
                    key={item.id}
                    ctaSize={ctaSize}
                    carouselItemSeen={carouselItemSeen}
                    carouselItemCTAClicked={carouselItemCTAClicked}
                    eagerImages={i === 0}
                  />
                );
              })}
            </Carousel>
          )}
        </ReserveCarouselSpace>
      </CarouselPlaceholderBg>
    </ThemeProvider>
  );
});
