// eslint-disable-next-line filename-rules/match
import { Icon, IconProps } from '@chakra-ui/react';

export const Web3AdvisoryPictogram = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      boxSize="12"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4 24C41.4 33.6098 33.6098 41.4 24 41.4C14.3902 41.4 6.6 33.6098 6.6 24C6.6 14.3902 14.3902 6.6 24 6.6C33.6098 6.6 41.4 14.3902 41.4 24ZM44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24ZM36.8002 24.05C36.8002 31.0916 31.0918 36.7999 24.0502 36.7999C17.0086 36.7999 11.3002 31.0916 11.3002 24.05C11.3002 17.0083 17.0086 11.3 24.0502 11.3C31.0918 11.3 36.8002 17.0083 36.8002 24.05ZM39.4002 24.05C39.4002 32.5275 32.5278 39.4 24.0502 39.4C15.5726 39.4 8.7002 32.5275 8.7002 24.05C8.7002 15.5724 15.5726 8.69995 24.0502 8.69995C32.5278 8.69995 39.4002 15.5724 39.4002 24.05Z"
        fill="currentColor"
      />
    </Icon>
  );
};
