import { useEffect, useMemo } from 'react';

import { AxiosQueryModel, useSeedrsClient } from '@r-client/shared/data/client';

export type APIResponse<T> = {
  result: T;
};

export interface IEuropeCardData {
  slug: string;
  coverImage: string;
  logo: string;
  title: string;
  description: string;
  currency: string;
  raisedAmountCents: number;
  targetAmountCents: number;
  preMoneyValuationCents: number;
  numberOfInvestors: number;
  percentageRaised: string;
  countryCode: string;
  city: string;
  type: string;
  typeForDisplay: string;
  status: string;
}

export interface IEUOfferingsQueryVariables {
  page_size: number;
  sort: string;
}

export type UnknownRecord = Record<string, unknown>;

export class EUOfferingsQuery extends AxiosQueryModel<
  APIResponse<UnknownRecord[]>,
  IEUOfferingsQueryVariables
> {
  protected query() {
    return this.client.get<APIResponse<UnknownRecord[]>>('/campaigns', {
      params: {
        page_size: this.variables?.page_size ?? 6,
        listed: 'true',
        sort: this.variables?.sort ?? 'trending_desc',
      },
    });
  }
}

export const useEuropeOfferingQuery = (
  variables: IEUOfferingsQueryVariables
) => {
  const client = useSeedrsClient();

  const data = useMemo<EUOfferingsQuery | undefined>(() => {
    return new EUOfferingsQuery({
      variables: { page_size: variables.page_size, sort: variables.sort },
      client,
    });
  }, [client, variables.page_size, variables.sort]);

  useEffect(() => {
    if (!data) return;
    data.fetch();
  }, [data]);
  return data;
};
