import { useEffect, useMemo, useState } from 'react';

import { useSeedrsClient } from '@r-client/shared/data/client';

import {
  getBusinessesCount,
  getCampaignsCount,
  getHomepage,
  HomePageObjectType,
} from '../../utils/api';
import { GLOBAL_STATS, REPUBLIC_STATS } from '../../utils/helper';
import { IStatBoxProps } from '../stat-box/stat-box';
import { StatsStateless } from '../stats-stateless/stats-stateless';
import { formatNumberWithCommasAndSuffix } from './utils';

export interface IStatsProps {
  region: string;
}

export type IStatsDeps =
  | {
      homepage: HomePageObjectType;
      campaignsCount: number;
      businessesCount: number;
    }
  | { error: unknown }
  | undefined;

export const Stats = ({ region }: IStatsProps) => {
  const [statsDeps, setStatsDeps] = useState<IStatsDeps>(undefined);
  const client = useSeedrsClient();

  useEffect(() => {
    const fetchStats = async () => {
      if (region === 'europe' && client) {
        try {
          const [homepage, campaignsCount, businessesCount] = await Promise.all(
            [
              getHomepage(client),
              getCampaignsCount(client),
              getBusinessesCount(client),
            ]
          );

          setStatsDeps({
            homepage,
            campaignsCount,
            businessesCount,
          });
        } catch (error) {
          setStatsDeps({
            error,
          });
        }
      }
    };

    fetchStats();
  }, [region, client]);

  const stats = useMemo((): IStatBoxProps[] => {
    if (region === 'us') {
      return REPUBLIC_STATS;
    }

    if (region === 'global') {
      return GLOBAL_STATS;
    }

    if (statsDeps !== undefined && 'error' in statsDeps) {
      return REPUBLIC_STATS;
    }

    return [
      {
        title: statsDeps
          ? `${formatNumberWithCommasAndSuffix(
              Number(statsDeps.homepage.stats.all_time_investment_in_cents) /
                100,
              true
            )}`
          : '',
        description: 'EUR tot. investments',
        isLoading: !statsDeps,
      },
      {
        title: statsDeps
          ? `${formatNumberWithCommasAndSuffix(
              statsDeps.homepage.stats.all_time_funded_deals
            )}+`
          : '',
        description: 'EUR tot. funded deals',
        isLoading: !statsDeps,
      },
      {
        title: statsDeps?.campaignsCount.toString() ?? '',
        description: 'Live opportunities',
        url: 'https://europe.republic.com/invest/raising-now',

        isLoading: !statsDeps,
      },
      {
        title: statsDeps?.businessesCount.toString() ?? '',
        description: 'Secondary Market',
        url: 'https://europe.republic.com/invest/marketplace',
        isLoading: !statsDeps,
      },
    ];
  }, [region, statsDeps]);

  return <StatsStateless stats={stats} region={region} />;
};
