import * as colors from '../../colors';
import { ColorsConfig, ThemeName } from '../types';

export const getColors = (themeName: ThemeName): ColorsConfig => {
  if (themeName !== 'investor') {
    /*eslint-disable-next-line no-console*/
    console.warn(
      `Color palette for "${themeName}" not found, defaulting to investor`
    );
    themeName = 'investor';
  }

  const { primary, secondary } = colors[themeName];

  return {
    ...colors.common,
    primary,
    secondary,
  };
};
