/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import {
  ITickerInvestmentsQuery,
  ITickerInvestmentsQueryVariables,
  TickerInvestmentsDocument,
} from './types';
import { IInvestmentTickerConnection } from '@r-client/data/graphql-types';
export type { IInvestmentTickerConnection };

export type ITickerInvestmentsQueryOpts =
  IQueryModelOpts<ITickerInvestmentsQueryVariables>;

export class TickerInvestmentsQuery extends BaseQueryModel<
  ITickerInvestmentsQuery,
  ITickerInvestmentsQueryVariables
> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: ITickerInvestmentsQueryOpts) {
    super(opts);
  }

  get query() {
    return TickerInvestmentsDocument;
  }
}
export function useTickerInvestmentsQuery(
  opts?: Omit<ITickerInvestmentsQueryOpts, 'client'>
) {
  const client = useGqlClient();
  return useQuery(
    () => new TickerInvestmentsQuery({ ...opts, client }),
    opts?.variables
  );
}
