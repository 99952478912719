import styled, { css } from 'styled-components';

import { media } from '../../../utils/media';

interface WrapperProps {
  backgroundColor?: string;
  height?: string;
  desktopHeight?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  flex-shrink: 0;

  ${(props) => {
    if (props.height) {
      return css`
        height: ${props.height} !important;
      `;
    } else {
      return css`
        height: 35rem;
        ${media(props.theme.grid.breakpoints.md)`
          height: ${props.desktopHeight || '17.125rem'};
        `}
      `;
    }
  }}
  position: relative;
  overflow: hidden;

  ${(props) => {
    if (props.backgroundColor) {
      return css`
        background-color: ${props.backgroundColor};
      `;
    }
  }}
`;
