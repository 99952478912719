import React from 'react';

import { ButtonLink } from '../button-link';
import { InView } from '../in-view';
import { Styles } from './carousel-item';
import { IItemProps } from './types';

const SeedrsProductCarouselItemNoMemo = ({
  itemId,
  overlayTheme,
  ctaText,
  ctaSize,
  ctaTheme,
  description,
  link,
  linkNewTab,
  title,
  disclaimer,
  theme,
  centeredContent,
  carouselItemSeen,
  carouselItemCTAClicked,
}: IItemProps) => {
  const handleView = React.useCallback(
    (inView: boolean) => {
      if (inView && carouselItemSeen) {
        carouselItemSeen(itemId);
      }
    },
    [carouselItemSeen, itemId]
  );

  const handleClick = React.useCallback(() => {
    if (carouselItemCTAClicked) {
      carouselItemCTAClicked(itemId);
    }
  }, [carouselItemCTAClicked, itemId]);

  return (
    <Styles.ContentWrapper centered={centeredContent}>
      <InView onChange={handleView}>
        {title && (
          <Styles.Header
            overlayTheme={overlayTheme}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Styles.DescriptionWrapper itemTheme={theme}>
          <Styles.DescriptionText
            overlayTheme={overlayTheme}
            dangerouslySetInnerHTML={{ __html: description ?? '' }}
          />
        </Styles.DescriptionWrapper>
        <Styles.ActionWrapper onClick={handleClick}>
          {ctaText && link && (
            <ButtonLink
              theme={ctaTheme}
              href={link}
              linkNewTab={linkNewTab}
              size={ctaSize}
            >
              {ctaText}
            </ButtonLink>
          )}
        </Styles.ActionWrapper>
        {disclaimer && (
          <Styles.DisclaimerOverlay
            itemTheme={theme}
            overlayTheme={overlayTheme}
          >
            {/* there needs to be dangerouslySetHtml here as there is often a link to T's and C's within this text */}
            <Styles.DisclaimerText
              dangerouslySetInnerHTML={{ __html: disclaimer }}
              overlayTheme={overlayTheme}
            />
          </Styles.DisclaimerOverlay>
        )}
      </InView>
    </Styles.ContentWrapper>
  );
};

export const SeedrsProductCarouselItem = React.memo(
  SeedrsProductCarouselItemNoMemo
);
