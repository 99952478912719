import { createIcon } from '@chakra-ui/react';

export const Check = createIcon({
  displayName: 'Check',
  viewBox: '0 0 24 24',
  path: (
    <g fill="currentColor" stroke="none">
      <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
    </g>
  ),
});
