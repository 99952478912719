import React from 'react';

import { ButtonLink } from '../button-link';
import { InView } from '../in-view';
import { makeMockComponent } from '../mock-component';
import { Styles } from './carousel-item';
import { ISecondaryMarketItemProps } from './types';

// TODO: MK recover this import (SecondaryMarketStatus)
// import SecondaryMarketStatus from 'components/secondary_market/SecondaryMarketStatus';

const SecondaryMarketStatus = makeMockComponent('SecondaryMarketStatus');

export const SecondaryMarketCarouselItem: React.FC<
  React.PropsWithChildren<ISecondaryMarketItemProps>
> = ({
  itemId,
  overlayTheme,
  ctaText,
  ctaTheme,
  description,
  link,
  linkNewTab,
  title,
  theme,
  centeredContent,
  carouselItemSeen,
  carouselItemCTAClicked,
  secondaryMarketStatus,
}) => {
  const handleView = React.useCallback(() => {
    if (carouselItemSeen) {
      carouselItemSeen(itemId);
    }
  }, [carouselItemSeen, itemId]);

  const handleClick = React.useCallback(() => {
    if (carouselItemCTAClicked) {
      carouselItemCTAClicked(itemId);
    }
  }, [carouselItemCTAClicked, itemId]);

  return (
    <Styles.ContentWrapper centered={centeredContent}>
      <InView onChange={handleView}>
        {title && (
          <Styles.Header
            overlayTheme={overlayTheme}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Styles.DescriptionWrapper itemTheme={theme}>
          <Styles.SecondaryMarketDescriptionText
            overlayTheme={overlayTheme}
            dangerouslySetInnerHTML={{ __html: description ?? '' }}
          />
        </Styles.DescriptionWrapper>

        {secondaryMarketStatus && (
          <SecondaryMarketStatus status={secondaryMarketStatus} />
        )}

        <Styles.ActionWrapper onClick={handleClick}>
          {ctaText && link && (
            <ButtonLink theme={ctaTheme} href={link} linkNewTab={linkNewTab}>
              {ctaText}
            </ButtonLink>
          )}
        </Styles.ActionWrapper>
      </InView>
    </Styles.ContentWrapper>
  );
};
