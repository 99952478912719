import React from 'react';

import {
  Icon as ChakraIcon,
  IconProps as BaseIconProps,
} from '@chakra-ui/react';

export interface IArrowIconProps extends BaseIconProps {
  direction: 'left' | 'right';
}

export const ArrowIcon = ({ direction, ...props }: IArrowIconProps) => (
  <ChakraIcon
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="6"
    viewBox="0 0 13.2 12.9"
    fill="currentColor"
    transform={direction === 'left' ? '' : 'rotate(180deg)'}
    {...props}
  >
    <path d="m6.4 12.9-6.4-6.5 6.4-6.4 1.5 1.5-3.9 3.9h9.1v2.2h-9.1l3.9 3.9z" />
  </ChakraIcon>
);
