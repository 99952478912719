import React, { useEffect } from 'react';

import { Track, TrackContainer } from './carousel/style';

const SWIPE_DISTANCE = 50;

interface CarouselTrackProps {
  animateRelative: number;
  next: () => void;
  previous: () => void;
  onComplete: () => void;
  speed: number;
}

export const CarouselTrack: React.FC<
  React.PropsWithChildren<CarouselTrackProps>
> = ({ animateRelative, next, previous, onComplete, speed, children }) => {
  const renderedSlides = React.Children.count(children);
  const [previousAnimate, setPreviousAnimate] = React.useState(animateRelative);

  // Use a ref so correct callback is called even if it's changed mid animation
  const callback = React.useRef(onComplete);

  useEffect(() => {
    callback.current = onComplete;
  }, [onComplete]);

  // Set timer to callback when CSS transition completes
  useEffect(() => {
    if (previousAnimate === 0 && animateRelative !== 0) {
      const timer = setTimeout(() => {
        callback.current();
      }, speed);

      return () => clearTimeout(timer);
    }
    setPreviousAnimate(animateRelative);
  }, [animateRelative]);

  // Gesture handlers
  const x0 = React.useRef<number | undefined>();
  const [drag, setDrag] = React.useState(0);

  const handleGestureStart = React.useCallback(
    (e: React.TouchEvent) => {
      if (animateRelative === 0 && x0.current === undefined) {
        x0.current = e.changedTouches[0].clientX;
      }
    },
    [animateRelative, x0]
  );

  const handleGestureMove = React.useCallback(
    (e: React.TouchEvent) => {
      if (x0.current !== undefined) {
        e.preventDefault();
        setDrag(e.changedTouches[0].clientX - x0.current);
      }
    },
    [x0]
  );

  const handleGestureEnd = React.useCallback(
    (e: React.TouchEvent) => {
      if (x0.current !== undefined) {
        const moved = e.changedTouches[0].clientX - x0.current;
        setDrag(0);
        x0.current = undefined;
        if (moved > SWIPE_DISTANCE) {
          previous();
        } else if (moved < -SWIPE_DISTANCE) {
          next();
        }
      }
    },
    [x0, next, previous]
  );

  return (
    <TrackContainer>
      <Track
        // Display current slide in the center
        offset={Math.floor(renderedSlides / 2)}
        // Animates left or right with CSS transitions
        animate={animateRelative}
        duration={speed / 1000}
        // Turn off animations when setting the current index
        transition={animateRelative !== 0}
        drag={drag}
        onTouchStart={handleGestureStart}
        onTouchMove={handleGestureMove}
        onTouchEnd={handleGestureEnd}
      >
        {children}
      </Track>
    </TrackContainer>
  );
};
