/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseQueryModel,
  IQueryModelOpts,
  useQuery,
  useGqlClient,
} from '@r-client/shared/data/client';
import { IGdprQuery, IGdprQueryVariables, GdprDocument } from './types';

export type IGdprQueryOpts = IQueryModelOpts<IGdprQueryVariables>;

export class GdprQuery extends BaseQueryModel<IGdprQuery, IGdprQueryVariables> {
  // This is fine. Here we explicitly change interface for query
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(opts: IGdprQueryOpts) {
    super(opts);
  }

  get query() {
    return GdprDocument;
  }
}
export function useGdprQuery(opts?: Omit<IGdprQueryOpts, 'client'>) {
  const client = useGqlClient();
  return useQuery(() => new GdprQuery({ ...opts, client }), opts?.variables);
}
