import {
  IOffering,
  IOfferingBadgeNameEnum,
} from '@r-client/data/graphql-types';

import {
  getTags,
  IOfferingCardProps,
  offeringTerms,
} from '../components/offering-card';
import { IOfferingCardFragment } from '../graphql/types';
import { E_OFFERING_INVESTMENT_BADGE_NAME } from '../types';

type IOfferingItem = IOfferingCardFragment;

export const isOffering = (node: IOfferingItem | undefined) => {
  return node?.__typename === 'Offering';
};

export const extractDealProps = (node: IOfferingItem): IOfferingCardProps => {
  const terms = offeringTerms(node as IOffering);
  const tags = getTags(node as IOffering);
  const address = isOffering(node)
    ? `${node?.address?.city}, ${node?.address?.state}`
    : undefined;
  const badges = isOffering(node)
    ? node?.badges?.filter(
        (b: IOfferingBadgeNameEnum) => b !== IOfferingBadgeNameEnum.Live
      )
    : undefined;

  const investmentBadge =
    isOffering(node) && node.investmentBadge && node.investmentBadge.description
      ? {
          description: node.investmentBadge
            .description as E_OFFERING_INVESTMENT_BADGE_NAME,
          amount: Number(node.investmentBadge.amountCents) ?? 0,
        }
      : undefined;

  if (isOffering(node)) {
    return {
      id: +node.id,
      slug: node.slug,
      companyName: node.companyName,
      description: node.description ?? '',
      cover: node.cardImageUrl ?? '',
      logoUrl: node.logoUrl ?? '',
      logoUrl2x: node.logoUrl2x ?? '',
      tooltipText: node.cardTooltipText ?? '',
      cardComplianceText: node.cardComplianceText ?? '',
      leftToInvestValue: node.timeToInvestBadgeParams?.value,
      leftToInvestUnit: node.timeToInvestBadgeParams?.unit,
      following: node.following,
      terms,
      badges,
      tags,
      investmentBadge,
      location: address,
      spotlight: !!node.spotlight,
      hideInvestmentDetailsOnDealCard: node.hideInvestmentDetailsOnDealCard,
    };
  }

  return {};
};
