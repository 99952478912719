import styled from 'styled-components';

import { media } from '../../utils/media';
import { rem } from '../../utils/styles';

interface Props {
  justifyCenter: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  justify-content: ${(props) =>
    props.justifyCenter ? 'center' : 'space-between'};
`;

export const InformationNotice = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.neutral.middark};
  margin-bottom: ${(props) => rem(props.theme.spacing.md)};
  font-size: ${rem(14)};
`;

export const Provider = styled.div`
  text-align: center;
  font-size: ${rem(14)};
`;

export const ReserveCarouselSpace = styled.div<{
  mobile: number;
  desktop: number;
  isLoading?: boolean;
}>`
  height: ${(props) => props.mobile}px;
  opacity: ${(props) => (props.isLoading ? '0' : '1')};
  transition: opacity 0.5s ease-in-out;

  ${(props) => media(props.theme.grid.breakpoints.md)`
    height: ${props.desktop}px;
  `}
`;

ReserveCarouselSpace.defaultProps = {
  isLoading: false,
};

export const CarouselPlaceholderBg = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.xlight};
`;

// this is a placeholder for the height of the carousel indicators
export const CarouselSeparator = styled.div`
  margin-bottom: ${rem(58)};
`;
