import { ReactNode } from 'react';

import { Box, Divider, Text } from '@chakra-ui/react';

export interface ISectionProps {
  heading: ReactNode;
  body: ReactNode;
}

export const Section = ({ heading, body }: ISectionProps) => {
  return (
    <Box mb="4">
      <Divider color="grey.100" mb="4" />
      <Text textStyle="lg" mb="2">
        {heading}
      </Text>

      <Text
        textStyle="sm"
        as={typeof body !== 'string' ? 'div' : undefined}
        color="grey.600"
      >
        {body}
      </Text>
    </Box>
  );
};
