import styled, { css } from 'styled-components';

import { GridConfig } from '../../../tokens/theme/types';
import { media } from '../../../utils/media';
import { IndicatorsColor } from '../types';

const pxToRem = (pixels: number, base = 16) => `${pixels / base}rem`;

export type RowsPerSlide = {
  [K in keyof GridConfig['breakpoints']]?: GridConfig['breakpoints'][K];
};

export const CarouselWrapper = styled.div<{
  isHomepageHeader?: boolean;
  showDots?: boolean;
}>`
  ${(props) =>
    props.isHomepageHeader
      ? `
        display: flex;
        justify-content: center;
      `
      : ''};

  position: relative;
  min-height: ${pxToRem(50)};
  ${(props) => (props.showDots ? 'margin-bottom: 30px;' : '')}
`;

export const ItemsContainer = styled.div<{
  hide?: boolean;
  rowsPerSlide: RowsPerSlide;
  isHomepageHeader?: boolean;
}>`
  ${(props) => (props.isHomepageHeader ? 'min-width: 100%;' : 'width: 100%')};
  ${(props) =>
    // Hide rowsPerSlide value in irrelevant css property
    Object.keys(props.rowsPerSlide).reduce(
      (acc, size) =>
        css`
          ${acc}
          ${media(props.theme.grid.breakpoints[size as keyof RowsPerSlide])`
          order: ${props.rowsPerSlide[size as keyof RowsPerSlide]};
        `}
        `,
      css``
    )}

  ${(props) =>
    props.hide
      ? `
  display: none;
  flex-wrap: wrap;
    `
      : `
  display: flex;
  overflow: hidden;
    `}
`;

export const TrackContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

interface TrackProps {
  animate: number;
  offset: number;
  drag: number;
  duration: number;
  transition: boolean;
}

// Use attrs to directly set styles for dragging performance
export const Track = styled.div.attrs<TrackProps>((props) => ({
  style: {
    // Hack for IE11 which doesn't support calc in translateX
    // Luckily translateX stacks, so instead of translateX(calc(x * -100%)),
    // we repeat translate(-100%) x times
    transform: `translateX(${props.drag}px) ${[
      ...Array(props.animate + props.offset),
    ]
      .map(() => 'translateX(-100%)')
      .join(' ')}`,
    transition: `transform ${props.transition ? props.duration : 0}s`,
  },
}))<TrackProps>`
  display: flex;
`;

export const Slide = styled.div<{ show?: boolean }>`
  width: 100%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

export const IndicatorArrow = styled.button<{ left?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  margin: auto;
  bottom: 0;
  top: 0;
  z-index: 2;
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  border-radius: 50%;
  border: ${pxToRem(1)} solid ${(props) => props.theme.colors.neutral.midlight};
  background-color: white;
  cursor: pointer;
  outline: none;
  ${(props) => {
    if (props.left) {
      return css`
        left: 2px;
      `;
    } else {
      return css`
        right: 2px;
      `;
    }
  }};

  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.xlight};
  }

  &:focus {
    box-shadow: 0 0 4px 0 ${(props) => props.theme.colors.neutral.middark};
  }
  svg {
    height: ${pxToRem(25)};
    width: ${pxToRem(25)};
    position: absolute;
    top: 23.5%;
    ${(props) => {
      if (props.left) {
        return css`
          left: 20.5%;
        `;
      } else {
        return css`
          right: 20.5%;
        `;
      }
    }};
  }
`;

export const Dots = styled.ul<{
  isHomepageHeader?: boolean;
  showArrows?: boolean;
  overBanner?: boolean;
}>`
  position: absolute;
  display: ${(props) => (props.showArrows ? 'none' : 'flex')};
  padding: 0;
  ${(props) => (props.overBanner ? 'bottom: 0px;' : '')}
  width: ${(props) => (props.isHomepageHeader ? 'fit-content' : '100%')};
  margin: 23px 0;
  list-style: none;
  justify-content: center;

  ${(props) => media(props.theme.grid.breakpoints.md)`
    display: flex;
  `}
`;

export const Dot = styled.li<{ color: IndicatorsColor }>`
  width: ${pxToRem(28)};
  height: ${pxToRem(28)};
  padding: 0;
  cursor: pointer;
  position: relative;

  button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: ${pxToRem(28)};
    height: ${pxToRem(28)};
    cursor: pointer;
    color: transparent;
    background-color: transparent;
    border: none;
  }

  &.active:after {
    background-color: ${(props) => props.theme.colors.neutral.midlight};
  }

  &:hover:after {
    background-color: ${(props) => props.theme.colors.neutral.midlight};
  }

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid ${(props) => props.theme.colors.neutral.midlight};
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;
