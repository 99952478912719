import {
  Accordion,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import { COMMUNITY_ENGAGEMENT } from './sections/community-engagement';
import { ONCHAIN_INFRASTRUCTURE } from './sections/onchain-infrastructure';
import { RAISE_CAPITAL } from './sections/raise-capital';
import { RWA_TOKENIZATION } from './sections/rwa-tokenization';
import { WEB3_ADVISORY } from './sections/web3-advisory';
import { useMobile } from './use-mobile';

export interface IGlobalForFoundersProps {
  headingRef: React.RefObject<HTMLHeadingElement>;
}

export const GlobalForFounders = ({ headingRef }: IGlobalForFoundersProps) => {
  const isMobile = useMobile();
  return (
    <Container maxWidth="1152px" py="20">
      <Text textStyle="sm" color="grey.500" ref={headingRef}>
        For businesses
      </Text>
      <Heading size="republic-h4" my="1">
        The digitization of assets is here. Republic is where it is happening.
      </Heading>
      <Text textStyle="md" color="grey.500" maxW="724px" mb="14">
        From raising capital to equity sharedrops, token design to tokenization,
        advisory to launch—Republic engineers digital products for businesses of
        any stage to realize their full potential.
      </Text>

      <Accordion>
        {isMobile ? (
          <>
            {RAISE_CAPITAL}
            {COMMUNITY_ENGAGEMENT}
            {RWA_TOKENIZATION}
            {ONCHAIN_INFRASTRUCTURE}
            {WEB3_ADVISORY}
          </>
        ) : (
          <>
            <SimpleGrid columns={[1, 2]} spacing="8">
              {RAISE_CAPITAL}
              {COMMUNITY_ENGAGEMENT}
              {RWA_TOKENIZATION}
              {ONCHAIN_INFRASTRUCTURE}
            </SimpleGrid>

            {WEB3_ADVISORY}
          </>
        )}
      </Accordion>
    </Container>
  );
};
