import accreditedInvestorsImage from './images/accredited-investors.png';
import accreditedInvestorsMobileImage from './images/accredited-investors-mobile.png';
import investorsEuropeImage from './images/investors-europe.png';
import investorsEuropeMobileImage from './images/investors-europe-mobile.png';
import investorsUsGlobalImage from './images/investors-us-global.png';
import investorsUsGlobalMobileImage from './images/investors-us-global-mobile.png';
import republicCapitalImage from './images/republic-capital.png';
import republicCapitalMobileImage from './images/republic-capital-mobile.png';

export interface IDealItem {
  link: string;
  target?: string;
  image: {
    src: string;
    maxHeight?: string;
    maxWidth?: string;
  };
  mobileImage: {
    src: string;
  };
  title: string;
  description: string;
  cta: string;
}

const DEFAULT_DEALS: IDealItem[] = [
  {
    link: '/dealroom',
    image: { src: accreditedInvestorsImage, maxHeight: '165px' },
    mobileImage: { src: accreditedInvestorsMobileImage },
    title: 'Accredited investors',
    description:
      'Co-invest with top venture funds in select private deals in our accredited deal room.',
    cta: 'View Deal Room',
  },
  {
    link: 'https://republiccapital.co/',
    target: '_blank',
    image: { src: republicCapitalImage, maxHeight: '97px' },
    mobileImage: { src: republicCapitalMobileImage },
    title: 'Institutional investors',
    description:
      'Institutional capital deployment and asset management with Republic Capital.',
    cta: 'See current portfolio',
  },
];

export function getDeals(region: 'us' | 'europe'): IDealItem[] {
  const regionalDeal =
    region === 'us'
      ? {
          link: '/companies',
          target: '_blank',
          image: { src: investorsUsGlobalImage, maxWidth: '185px' },
          mobileImage: { src: investorsUsGlobalMobileImage },
          title: 'US and international residents',
          description: 'Republic operates world’s leading investment platform.',
          cta: 'View all deals',
        }
      : {
          link: 'https://europe.republic.com/invest/raising-now?sort=trending_desc&sort_collection=campaigns',
          target: '_blank',
          image: { src: investorsEuropeImage, maxWidth: '185px' },
          mobileImage: { src: investorsEuropeMobileImage },
          title: 'UK and EU residents',
          description:
            'Republic operates Europe’s leading investment platform Seedrs. View 750+ live deals.',
          cta: 'View European deals',
        };

  return [regionalDeal, ...DEFAULT_DEALS];
}
