import { CommunityEngagementPictogram } from '@r-client/dls/ui/pictograms';

import { CardButton } from '../../card-button/card-button';
import { Section } from '../section';

export const COMMUNITY_ENGAGEMENT = (
  <Section icon={CommunityEngagementPictogram} title="Community engagement">
    <CardButton
      title="Token launchpad"
      description="Market entry for creators and investors"
      href="/launchpad"
    />
    <CardButton
      title="Equity sharedrops™"
      description="Reward your community with shares"
      href="/sharedrops"
    />
  </Section>
);
