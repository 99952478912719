// Determines which row of a flex-wrap container each child appears in
// Returns an array A such that A[n] = row number for child n
// Assumes that 1 HTMLElement = 1 React child, so wrap each child in a div if
// necessary
export function getRowLayout(element: HTMLElement) {
  const items = Array.from(element.children) as HTMLElement[];

  const display = element.style.display;
  const flexWrap = element.style.flexWrap;

  element.style.display = 'flex';
  element.style.flexWrap = 'wrap';

  // Assign elements to rows based on their offsetTop
  const rows = items.reduce<{ top: number; items: HTMLElement[] }[]>(
    (acc, item) => {
      const lastRow = acc[acc.length - 1];
      if (acc.length === 0 || item.offsetTop > lastRow.top) {
        return acc.concat({
          top: item.offsetTop,
          items: [item],
        });
      }
      return acc.slice(0, -1).concat({
        top: lastRow.top,
        items: lastRow.items.concat(item),
      });
    },
    []
  );

  element.style.display = display;
  element.style.flexWrap = flexWrap;

  // Convert to array of row numbers
  return rows.reduce<number[]>((rowNumbers, row, i) => {
    return rowNumbers.concat(Array.from(new Array(row.items.length), () => i));
  }, []);
}

// a % b, but returns +ve number for -ve inputs
// 1 % 3 = 1
// positiveModulo(1, 3) = 1
// -1 % 3 = -1
// positiveModulo(-1, 3) = 2
export function positiveModulo(a: number, b: number) {
  return (b + (a % b)) % b;
}

// Returns an array of numbers from start to end
export function range(start: number, end: number) {
  const length = end - start;
  if (Number.isNaN(length)) {
    return [];
  }

  const sign = length >= 0 ? 1 : -1;

  return Array.from(new Array(Math.abs(length)), (_, i) => start + sign * i);
}
