import { createIcon } from '@chakra-ui/icons';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'currentColor',
  },
  path: (
    <path
      d="M20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929L13.7071 5.29289C13.3166 4.90237 12.6834 4.90237 12.2929 5.29289C11.9024 5.68342 11.9024 6.31658 12.2929 6.70711L16.5858 11L5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13L16.5858 13L12.2929 17.2929C11.9024 17.6834 11.9024 18.3166 12.2929 18.7071C12.6834 19.0976 13.3166 19.0976 13.7071 18.7071L19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12Z"
      fill="currentColor"
    />
  ),
});
