import { RwaTokenizationPictogram } from '@r-client/dls/ui/pictograms';

import { CardButton } from '../../card-button/card-button';
import { Section } from '../section';

export const RWA_TOKENIZATION = (
  <Section icon={RwaTokenizationPictogram} title="RWA tokenization">
    <CardButton
      title="Institutional funds"
      description="Open access to all levels of investor"
      href="/tokenization"
    />
    <CardButton
      title="Equity"
      description="Transform securities into accessible investments"
      href="/tokenization"
    />
    <CardButton
      title="Debt"
      description="Leverage worldwide networks for funding"
      href="/tokenization"
    />
    <CardButton
      title="Commodities"
      description="Democratize access for easier trading"
      href="/tokenization"
    />
  </Section>
);
