import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { InfiniteCarousel } from '@r-client/shared/feature/carousel';

import { Stats } from '../stats/stats';

export interface IGlobalHeaderProps {
  onClickRaiseCapital?: () => void;
  onClickViewDeals?: () => void;
}

export const GlobalHeader = observer(function GlobalHeader(
  props: IGlobalHeaderProps
) {
  return (
    <Box
      py="20"
      bg="radial-gradient(50% 50% at 60% -2%, rgba(21, 96, 250, 0.10) 0%, rgba(21, 96, 250, 0.00) 100%);"
    >
      <Flex
        alignItems="center"
        textAlign={'center'}
        mx="auto"
        px="4"
        direction="column"
        maxWidth="700px"
      >
        <Heading
          size="republic-h1"
          position="relative"
          mb="5"
          fontWeight="semibold"
          lineHeight="72px"
        >
          Worth building
          <Text
            as="span"
            position="absolute"
            top="-5px"
            right="-10px"
            fontSize="20px"
          >
            &trade;
          </Text>
        </Heading>
        <Text textStyle="md" fontSize="18px" mb="5" color="grey.600">
          Merging private market assets with the latest web3 technologies —
          redefining how people invest and how businesses connect with their
          customers
        </Text>
        <HStack my="2.5" spacing="4">
          <Button
            variant="republic-primary"
            fontSize="lg"
            fontWeight="bold"
            px="8"
            py="6"
            onClick={props.onClickViewDeals}
            size="md"
          >
            View deals
          </Button>
          <Button
            fontSize="lg"
            fontWeight="bold"
            px="8"
            py="6"
            onClick={props.onClickRaiseCapital}
            variant="republic-default"
            size="md"
          >
            Raise capital
          </Button>
        </HStack>
        <Text size="republic-body-sm" color="grey.500" mt="5">
          Private investments are highly risky, illiquid and may result in total
          loss of capital.{' '}
          <Link variant="republic-underlined" href="/risks">
            Learn more
          </Link>
        </Text>
      </Flex>
      <InfiniteCarousel my="10" carouselSlug="home_global" />
      <Container maxW="1152px">
        <Stats region="global" />
      </Container>
    </Box>
  );
});
