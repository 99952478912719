import { BigNumber } from 'bignumber.js';

import {
  createFormatter,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
  FormatterFloatDigitsBigNumber,
} from './intl-formatter';
import { formatAmountWithLetters } from './number';

const formatterWithAmountDigits = (
  decimalPlaces: number,
  currency = DEFAULT_CURRENCY
) => createFormatter(true, false, decimalPlaces, true, currency);

export const formatterFoaltDigitsBigNumberWithCurrencyPrefix = (
  currency: string
) => {
  const knownPrefixes: Record<string, string> = {
    EUR: '€',
    GBP: '£',
    USD: '$',
  };

  const currencyPrefix = knownPrefixes[currency] ?? currency;

  return {
    ...FormatterFloatDigitsBigNumber,
    prefix: currencyPrefix,
  };
};

export const FormatterFloatDigitsBigNumberWith$Prefix =
  formatterFoaltDigitsBigNumberWithCurrencyPrefix('USD');

// TODO: Use round=auto as default setting
export const formatCurrency = (
  currency: string,
  _amount?: number | BigNumber,
  round: boolean | 'auto' = true,
  withLetters = false,
  decimalPlaces = 2
) => {
  let letter = '';

  const formatter = formatterFoaltDigitsBigNumberWithCurrencyPrefix(currency);
  const formatterWithAmountRounded = createFormatter(
    undefined,
    undefined,
    undefined,
    undefined,
    currency
  );

  if (_amount instanceof BigNumber) {
    let newAmount$ = _amount;
    if (withLetters) {
      ({ newAmount: newAmount$, letter } = formatAmountWithLetters<BigNumber>(
        _amount,
        false
      ));
    }
    if (round === true) {
      return `${newAmount$.toFormat(0, formatter)}${letter}`;
    }
    if (round === 'auto') {
      return `${newAmount$
        .toFormat(decimalPlaces, formatter)
        .replace(/\.?0+$/, '')}${letter}`;
    }
    return `${newAmount$.toFormat(decimalPlaces, formatter)}${letter}`;
  }
  const amount = _amount ?? 0;
  let newAmount = amount;

  if (withLetters) {
    ({ newAmount, letter } = formatAmountWithLetters<number>(amount, false));
  }
  if ((round === 'auto' && Number.isInteger(newAmount)) || round === true) {
    return `${formatterWithAmountRounded.format(newAmount)}${letter}`;
  } else if (round === 'auto') {
    return `${formatterWithAmountDigits(decimalPlaces, currency)
      .format(newAmount)
      .replace(/\.?0+$/, '')}${letter}`;
  }

  return `${formatterWithAmountDigits(decimalPlaces, currency).format(
    newAmount
  )}${letter}`;
};

// TODO: Use round=auto as default setting
export const formatDollars = (
  _amount?: number | BigNumber,
  round: boolean | 'auto' = true,
  withLetters = false,
  decimalPlaces = 2
) => {
  return formatCurrency('USD', _amount, round, withLetters, decimalPlaces);
};

const formatFractionalPriceCache: Record<string, Intl.NumberFormat> = {};
export const formatFractionalPrice = (
  currency: string = DEFAULT_CURRENCY,
  amount: number | BigNumber,
  maximumFractionDigits = 0
) => {
  if (amount instanceof BigNumber) {
    return amount.toFormat(
      maximumFractionDigits,
      FormatterFloatDigitsBigNumberWith$Prefix
    );
  }

  const formatter =
    formatFractionalPriceCache[maximumFractionDigits] ||
    (formatFractionalPriceCache[maximumFractionDigits] = new Intl.NumberFormat(
      DEFAULT_LOCALE,
      {
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: maximumFractionDigits,
        style: 'currency',
      }
    ));
  return formatter.format(amount);
};

export const centsToMainUnit = <T extends number | BigNumber>(cents?: T): T => {
  if (cents instanceof BigNumber) {
    return cents.div(100) as T;
  } else if (typeof cents === 'number') {
    return (cents / 100) as T;
  } else {
    return 0 as T;
  }
};
export const mainUnitToCents = (mainUnitAmount?: number) =>
  mainUnitAmount ? mainUnitAmount * 100 : 0;

export const centsToDollars = centsToMainUnit;
export const dollarsToCents = mainUnitToCents;

// transform $10,100.50 to 10100.5
// based on https://stackoverflow.com/a/59636570/3590952
export const unformatAmount = (amount: string): number => {
  const num = 123456.789;
  const formatToLocale = new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: DEFAULT_CURRENCY,
  });
  const partsLocal = formatToLocale.formatToParts(num);
  let group = '';
  let decimal = '';
  let currency = '';

  partsLocal.forEach((i) => {
    switch (i.type) {
      case 'group':
        group = i.value;
        break;
      case 'decimal':
        decimal = i.value;
        break;
      case 'currency':
        currency = i.value;
        break;
      default:
        break;
    }
  });

  return parseFloat(
    amount
      .replace(new RegExp(`\\${group}`, 'g'), '')
      .replace(new RegExp(`\\${decimal}`), '.')
      .replace(new RegExp(`\\${currency}`, 'g'), '')
  );
};

/**
 * According to legal requirements user must have a REG A+ investment limit to make a deal.
 * 10% of the bigger amount (Yearly Income or Net worth)
 */
export function regAInvestmentLimit(
  netWorth: number | undefined,
  yearlyIncome: number | undefined
) {
  const amount =
    netWorth === undefined
      ? yearlyIncome
      : yearlyIncome === undefined
      ? netWorth
      : Math.max(netWorth, yearlyIncome);

  return amount !== undefined ? amount * 0.1 : undefined;
}

export const formatCurrencyFromCents = (
  currency: string,
  amount?: number | BigNumber,
  round: boolean | 'auto' = true,
  withLetters = false,
  decimalPlaces = 2
) =>
  formatCurrency(
    currency,
    centsToMainUnit(amount),
    round,
    withLetters,
    decimalPlaces
  );

export const formatDollarsFromCents = (
  amount?: number | BigNumber,
  round: boolean | 'auto' = true,
  withLetters = false,
  decimalPlaces = 2
) => formatCurrencyFromCents('USD', amount, round, withLetters, decimalPlaces);
