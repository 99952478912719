/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosInstance } from 'axios';

import { APIResponse, PaginatedAPIResponse } from './types';

export interface HomePageStats {
  all_time_funded_deals: number;
  all_time_investment_in_cents: string;
  last_quarter_investment_increase_percentage: string;
}

export interface StatsObjectType {
  all_time_investment_in_cents: string;
  last_quarter_investment_increase_percentage: string;
  all_time_investors: number;
  last_quarter_investors_increase_percentage: string | number;
  current_market_sold_shares_in_cents: number;
  current_market_available_shares_in_cents: number;
}

interface GenericPageObject {
  carousel_slug: string;
  slug: string;
  title: string;
  subtitle: string | null;
  background_color: string;
  stats_background_color: string;
  tile_image_location: string;
  seo_image_location: string;
  render_stats: boolean;
}

export interface HomePageObjectType extends GenericPageObject {
  stats: HomePageStats;
}

export async function getHomepage(client: AxiosInstance) {
  return await client
    .get<APIResponse<HomePageObjectType>>('/pages/homepage')
    .then((response) => response.data.result);
}

export async function getBusinessesCount(client: AxiosInstance) {
  return await client
    .get<PaginatedAPIResponse<never>>('/businesses', {
      params: { funded: true, available_shares: true },
    })
    .then((response) => response.data.meta.pagination.count);
}

export async function getCampaignsCount(client: AxiosInstance) {
  return await client
    .get<PaginatedAPIResponse<never>>('/campaigns')
    .then((response) => response.data.meta.pagination.count);
}
