export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_CURRENCY = 'USD';

const formatterFloatDigitsCache: Record<string, Intl.NumberFormat> = {};
export const createFormatterFloatDigits = (rounded: number) =>
  formatterFloatDigitsCache[rounded] ??
  (formatterFloatDigitsCache[rounded] = new Intl.NumberFormat(DEFAULT_LOCALE, {
    maximumFractionDigits: rounded,
    minimumFractionDigits: rounded,
  }));

export const FormatterFloatDigitsBigNumber = {
  groupSize: 3,
  groupSeparator: ',',
  decimalSeparator: '.',
};

const formatterCache: Record<string, Intl.NumberFormat> = {};
export const createFormatter = (
  withCurrency = true,
  rounded = true,
  decimalPlaces = 2,
  useGrouping = true,
  currency = DEFAULT_CURRENCY
): Intl.NumberFormat => {
  const cacheKey = `${withCurrency ? `currency_${currency}_` : ''}${
    rounded ? 'rounded_' : ''
  }${decimalPlaces}d${useGrouping ? '_grp' : ''}`;

  return (
    formatterCache[cacheKey] ??
    (formatterCache[cacheKey] = new Intl.NumberFormat(DEFAULT_LOCALE, {
      currency: withCurrency ? currency : undefined,
      minimumFractionDigits: rounded ? 0 : decimalPlaces,
      maximumFractionDigits: rounded ? 0 : decimalPlaces,
      style: withCurrency ? 'currency' : 'decimal',
      useGrouping,
    }))
  );
};
