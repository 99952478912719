export function rem(px: number | string, base = 16) {
  const pixels = typeof px === 'number' ? px : parseFloat(px);
  return `${pixels / base}rem`;
}

export function em(px: number | string, base = 16) {
  const pixels = typeof px === 'number' ? px : parseFloat(px);
  return `${pixels / base}em`;
}

type Value = string | number;

function pxOrString(a: Value) {
  if (typeof a === 'string') {
    return a;
  }
  return `${a}px`;
}

function spacing(
  type: string,
  a: Value,
  b?: Value,
  c?: Value,
  d?: Value
): string {
  let ret = `${type}:${pxOrString(a)}`;
  if (b !== undefined) {
    ret += ` ${pxOrString(b)}`;
  }
  if (c !== undefined) {
    ret += ` ${pxOrString(c)}`;
  }
  if (d !== undefined) {
    ret += ` ${pxOrString(d)}`;
  }
  return ret + ';';
}

export function padding(all: Value): string;
export function padding(vertical: Value, horizontal: Value): string;
export function padding(top: Value, horizontal: Value, bottom: Value): string;
export function padding(
  top: Value,
  right: Value,
  bottom: Value,
  left: Value
): string;
export function padding(...args: [Value, Value?, Value?, Value?]) {
  return spacing('padding', ...args);
}

export function margin(all: Value): string;
export function margin(vertical: Value, horizontal: Value): string;
export function margin(top: Value, horizontal: Value, bottom: Value): string;
export function margin(
  top: Value,
  right: Value,
  bottom: Value,
  left: Value
): string;
export function margin(...args: [Value, Value?, Value?, Value?]) {
  return spacing('margin', ...args);
}

export function transitions(...args: string[]) {
  return `transition:${args.join(',')};`;
}
