import { ColorsConfig } from '../../theme/types';
import { common } from '../common';

export const investor: Pick<ColorsConfig, 'primary' | 'secondary'> = {
  primary: {
    ...common.blue,
  },
  secondary: {
    ...common.purple,
  },
};
