import { ReactNode } from 'react';

import {
  IOffering,
  IOfferingStateEnum,
  ITeaserPage,
} from '@r-client/data/graphql-types';
import { Tag } from '@r-client/shared/ui/core';
import { formatDollarsFromCents } from '@r-client/shared/util/core';

import { isTeaserPage } from './tags';

type TTerm = {
  value: number | string;
  nextTierValue?: string;
  title: string;
  valuePreNode?: ReactNode;
  isDollar?: boolean;
  color?: string;
  tooltip?: string | null;
};

export const offeringTerms = (offering: IOffering | ITeaserPage) => {
  if (isTeaserPage(offering)) {
    return null;
  }

  const security = offering?.xSecurity;
  let title = 'raised';

  if (offering?.testingTheWaters) {
    title = 'reserved';
  } else if (offering?.testingTheWatersFirstDaysOff) {
    title = 'committed and reserved';
  }

  const amountRaisedCents = Number(offering.amountRaisedWithExternalCents) || 0;

  const terms: TTerm[] = [];

  terms.push({
    title: title,
    value: `${formatDollarsFromCents(amountRaisedCents)}`,
    isDollar: true,
    color: offering?.maxGoalReached ? '#19c157' : undefined,
    valuePreNode: offering?.maxGoalReached ? (
      <>
        <Tag size="small" bgColor="#19c157" noBorder={true} whiteText>
          MAX
        </Tag>
        &nbsp;
      </>
    ) : undefined,
    tooltip: offering.externalInvestmentsAmountTooltip,
  });

  if (!offering.timeRemainingValueForDisplay) {
    terms.push({
      title: 'investors',
      value: Number(offering?.investorsCount),
      tooltip: offering.externalInvestorsQuantity
        ? offering.externalInvestorsDescription
        : undefined,
    });
  }

  if (offering?.state === IOfferingStateEnum.Published) {
    if (offering.flexibleDealTerms?.length) {
      offering.flexibleDealTerms
        .filter((t) => t.showOnTombstone)
        .forEach((t) => {
          terms.push({
            title: t?.title?.toLocaleLowerCase() ?? '',
            value: t?.xValueForDealCard ?? '',
            nextTierValue: t?.nextTierValue ?? '',
          });
        });
    } else {
      terms.push({
        title: 'min. investment',
        value: formatDollarsFromCents(
          Number(offering?.minInvestmentAmountCents)
        ),
        isDollar: true,
      });
      if (security?.xValuationCents) {
        terms.push({
          title: 'valuation',
          value: `${formatDollarsFromCents(Number(security?.xValuationCents))}`,
          isDollar: true,
        });
      } else if (security?.xValuationCapCents) {
        terms.push({
          title: 'valuation cap',
          value: formatDollarsFromCents(Number(security?.xValuationCapCents)),
          isDollar: true,
        });
      }
    }
  }
  if (offering.timeRemainingValueForDisplay) {
    const value = offering.timeRemainingValueForDisplay;
    terms.push({
      title: `${offering.timeRemainingUnitForDisplay} remaining`,
      value: value < 0 ? 0 : value,
      valuePreNode: '🔥',
    });
  }
  if (offering?.hasClosed && offering?.reviewsCount) {
    terms.push({
      title: `review${offering.reviewsCount > 1 ? 's' : ''}`,
      value: offering?.reviewsCount,
    });
  }

  return terms;
};
