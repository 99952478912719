import { Box, Text } from '@chakra-ui/react';

export const MainLoggedOut = function MainLoggedOut() {
  return (
    <Box
      color="rgba(255, 255, 255, 0.5)"
      fontSize="13px"
      letterSpacing="normal"
      lineHeight="short"
    >
      <Text mb={4}>
        <Text as="span" fontSize="12px">
          1
        </Text>{' '}
        Represents users of republic.com, limited partners of Republic Capital
        Adviser LLC advised vehicles, users of Republic.europe.com and clients of other
        affiliates.
      </Text>
      <Text mb={4}>
        <Text as="span" fontSize="12px">
          2
        </Text>{' '}
        Includes aggregate figures from Republic and Seedrs platform to date,
        including investment opportunities presented by OpenDeal Portal LLC,
        OpenDeal Broker LLC, Republic Core LLC, Republic Deal Room Advisor LLC
        and Fig Publishing, Inc.
      </Text>
    </Box>
  );
};
