export const formatNumberWithCommasAndSuffix = (
  num: number,
  showCurrency = false
): string => {
  const roundedNum = Math.round(num);

  const million = 1_000_000;
  const billion = 1_000_000_000;

  let returnValue = null;

  if (roundedNum >= billion) {
    const billions = roundedNum / billion;
    returnValue = `${billions.toFixed(1)}B`;
  } else if (roundedNum >= million) {
    const millions = roundedNum / million;
    returnValue = `${millions.toFixed(1)}M`;
  } else {
    returnValue = roundedNum.toLocaleString();
  }

  if (showCurrency) {
    returnValue = `£${returnValue}`;
  }

  return returnValue;
};
