import { Icon, IconProps } from '@chakra-ui/react';

export const Galaxy = (props: IconProps) => {
  return (
    <Icon width="108" height="32" viewBox="0 0 108 32" fill="none" {...props}>
      <path
        d="M14.1567 0.746367C9.98752 1.14437 6.306 3.06902 3.65935 6.23816C1.6134 8.6915 0.391183 11.6973 0.13544 14.9139C0.0967806 15.4129 0.0967806 16.7643 0.13544 17.2604C0.367393 20.177 1.40226 22.9363 3.13894 25.2649C3.50471 25.755 3.90915 26.2361 4.28979 26.6312L4.59906 26.9519L4.59608 29.138V31.324H15.4949H26.3938V29.1528V26.9787L26.6703 26.6935C28.9215 24.3501 30.3875 21.2641 30.7949 18.0177C30.8812 17.3316 30.9139 16.8 30.9139 16.0693C30.9139 12.7398 29.8463 9.53205 27.845 6.86189C27.0212 5.76294 25.8823 4.61646 24.8117 3.81452C22.5457 2.10966 20.0418 1.11466 17.1602 0.773098C16.5922 0.707754 14.7426 0.689934 14.1567 0.746367ZM26.3938 16.0871V26.9579H15.5098H4.62582V16.0871V5.21643H15.5098H26.3938V16.0871Z"
        fill="#4D4D4D"
      />
      <path
        d="M66.7449 5.24057L66.6794 5.297L66.7151 9.574C66.7478 13.0134 66.7478 14.7064 66.7151 18.2201C66.6794 22.4317 66.6794 22.5951 66.733 22.6515C66.7835 22.708 66.8252 22.7109 67.8243 22.7109C68.8295 22.7109 68.8622 22.708 68.9217 22.6486C68.9811 22.5892 68.9811 22.5832 68.9603 20.6229C68.9098 15.6272 68.9068 13.7589 68.9425 9.71657C68.9633 7.33155 68.9722 5.35343 68.9633 5.31779C68.9365 5.19601 68.8473 5.1871 67.8065 5.1871C66.8876 5.1871 66.8103 5.19007 66.7449 5.24057Z"
        fill="#4D4D4D"
      />
      <path
        d="M57.5735 9.60368C56.7795 9.70467 56.0391 9.97198 55.4294 10.3789C53.9901 11.3442 53.0831 13.0609 52.8274 15.3153C52.7739 15.7786 52.7828 16.9399 52.8423 17.4389C53.1307 19.8447 54.1507 21.5763 55.7744 22.4109C56.3186 22.6901 56.8241 22.8446 57.4486 22.9218C57.8739 22.9723 58.8314 22.9426 59.1883 22.8654C60.4254 22.5951 61.371 21.9595 62.1026 20.9051L62.2632 20.6764L62.2394 21.6357C62.2215 22.5327 62.2215 22.5981 62.2721 22.6515C62.3226 22.708 62.3672 22.7109 63.3634 22.7109C64.6184 22.7109 64.5173 22.7495 64.5143 22.2713C64.5143 22.0961 64.4964 20.6496 64.4786 19.0576C64.4489 16.8122 64.4489 15.5321 64.4786 13.355C64.4964 11.8105 64.5143 10.4116 64.5143 10.2423C64.5173 9.78486 64.6154 9.8205 63.3634 9.8205C62.4594 9.8205 62.3226 9.82644 62.2602 9.86803L62.1918 9.91555L62.2096 10.8125C62.2275 11.6768 62.2275 11.7036 62.1769 11.6323C61.6714 10.9313 61.2729 10.5482 60.6811 10.1947C60.2321 9.92743 59.5897 9.71358 58.9861 9.62745C58.7006 9.58883 57.8174 9.57101 57.5735 9.60368ZM59.5094 11.3323C60.0774 11.4481 60.6276 11.7481 61.038 12.1639C61.6714 12.8055 62.0639 13.7441 62.2483 15.048C62.3226 15.5707 62.3197 17.0142 62.2483 17.5429C62.0728 18.8082 61.7071 19.6814 61.0915 20.3229C60.5473 20.8873 59.902 21.1813 59.0426 21.2556C57.3327 21.4041 56.0718 20.513 55.4919 18.7458C55.0607 17.4359 55.0101 15.443 55.3729 14.0589C55.7714 12.5233 56.7052 11.558 58.0136 11.3204C58.3527 11.2581 59.1853 11.264 59.5094 11.3323Z"
        fill="#4D4D4D"
      />
      <path
        d="M75.3871 9.60014C74.5247 9.73083 73.8407 9.98032 73.243 10.3813C71.6847 11.4297 70.7391 13.3752 70.6171 15.7959C70.5131 17.8601 70.9978 19.7283 71.9761 21.0471C72.2081 21.3589 72.7226 21.8579 73.0318 22.0777C73.5641 22.4519 74.2124 22.7252 74.8994 22.8648C75.1997 22.9242 75.3216 22.9331 75.9669 22.9331C76.6093 22.9301 76.7312 22.9242 76.9988 22.8648C78.2538 22.5915 79.1756 21.9708 79.922 20.8986L80.0767 20.6758L80.0559 21.6352C80.038 22.5262 80.038 22.6004 80.0886 22.6539C80.1362 22.7074 80.1897 22.7103 81.177 22.7103C82.1791 22.7103 82.2148 22.7074 82.2713 22.6509C82.3338 22.5886 82.3338 22.5767 82.31 20.6075C82.2802 18.0769 82.2802 14.3227 82.31 11.8634C82.3338 9.95359 82.3338 9.94171 82.2713 9.87933C82.2148 9.8229 82.1791 9.81993 81.171 9.81993C80.1599 9.81993 80.1272 9.8229 80.0678 9.8823C80.0083 9.94171 80.0083 9.9625 80.0261 10.7347C80.035 11.1713 80.0499 11.5812 80.0588 11.6466L80.0737 11.7654L79.9161 11.5367C79.2113 10.5149 78.34 9.94171 77.0791 9.67142C76.8412 9.61796 76.6598 9.60608 76.1305 9.59717C75.7707 9.59123 75.4376 9.5942 75.3871 9.60014ZM77.32 11.3317C78.9348 11.6644 79.9012 13.099 80.1034 15.4632C80.148 15.9652 80.1213 17.1324 80.0559 17.6017C79.7852 19.5709 78.8961 20.7976 77.4895 21.154C76.9156 21.2995 76.1721 21.3055 75.6339 21.1688C74.1975 20.8005 73.3024 19.5264 73.0229 17.4413C72.9248 16.7107 72.9307 15.5196 73.0378 14.8098C73.3292 12.8257 74.3165 11.5931 75.8272 11.3199C76.1662 11.2575 76.9988 11.2664 77.32 11.3317Z"
        fill="#4D4D4D"
      />
      <path
        d="M44.3967 9.64491C41.6965 9.88549 39.826 12.0596 39.5108 15.3268C39.4662 15.7872 39.4721 16.7732 39.5227 17.2455C39.8646 20.4681 41.7084 22.4284 44.5662 22.6185C46.4069 22.7403 47.8492 22.072 48.8454 20.6315L49 20.4087L48.9852 21.5374C48.9703 22.7581 48.9465 22.9808 48.7711 23.5868C48.5629 24.3055 48.1198 24.956 47.5845 25.3391C46.7519 25.9302 45.4286 26.1114 44.1855 25.7995C43.0614 25.5173 42.3834 24.8372 42.1515 23.765C42.1098 23.5808 42.0741 23.4828 42.0325 23.4442C41.976 23.3996 41.8779 23.3937 40.9649 23.3937C39.7903 23.3937 39.82 23.3878 39.8319 23.658C39.8379 23.8897 39.936 24.359 40.0431 24.6738C40.2393 25.2649 40.5248 25.7163 40.9798 26.1708C41.7292 26.9193 42.767 27.3677 44.1766 27.5578C44.5632 27.6083 46.0649 27.6083 46.4367 27.5578C47.2396 27.445 47.8492 27.2668 48.4439 26.9727C50.0022 26.2005 50.9062 24.7689 51.1055 22.7551C51.1233 22.5739 51.1382 20.0226 51.1441 16.1822L51.156 9.90628L51.0936 9.86173C51.043 9.82906 50.8527 9.82015 50.0141 9.82015C49.0328 9.82015 48.9911 9.82312 48.9346 9.87955C48.8662 9.94786 48.8662 9.88549 48.9138 10.9874C48.9465 11.6973 48.9435 11.7775 48.9049 11.7151C47.9146 10.2033 46.3237 9.47264 44.3967 9.64491ZM46.2017 11.3319C47.5548 11.6319 48.4737 12.7101 48.8246 14.409C48.9614 15.0624 48.9792 15.2793 48.9792 16.2505C48.9792 16.9782 48.9673 17.2425 48.9257 17.5128C48.6343 19.4107 47.8135 20.5156 46.4218 20.8899C46.1452 20.9641 46.0828 20.9701 45.4851 20.9701C44.7684 20.973 44.5424 20.9404 44.0814 20.7681C42.7908 20.284 42.0087 19.0009 41.8303 17.0673C41.7887 16.6277 41.7887 15.6862 41.8303 15.2674C41.9671 13.8506 42.4042 12.7814 43.1239 12.1072C43.5432 11.7151 44.0725 11.4418 44.6227 11.3379C44.7119 11.3201 44.813 11.2993 44.8457 11.2933C44.8784 11.2874 45.1401 11.2815 45.4256 11.2785C45.836 11.2785 45.9995 11.2874 46.2017 11.3319Z"
        fill="#4D4D4D"
      />
      <path
        d="M83.8496 9.86211C83.8198 9.89478 83.8198 9.92151 83.8496 9.97497C83.8704 10.0136 84.7417 11.3561 85.7855 12.96L87.6828 15.8796L85.5744 19.2418C83.8496 21.9892 83.4689 22.6129 83.4927 22.6575C83.5195 22.708 83.5879 22.7109 84.6049 22.7109C85.3692 22.7109 85.7112 22.702 85.7647 22.6753C85.8212 22.6515 86.2643 21.9476 87.4627 19.9873C88.3578 18.526 89.0983 17.3409 89.1102 17.3558C89.125 17.3677 89.8447 18.5409 90.716 19.9636C91.5843 21.3833 92.3278 22.5803 92.3694 22.6218L92.4408 22.6961H93.6689C94.8882 22.6961 94.8941 22.6961 94.9031 22.6337C94.909 22.5951 94.1953 21.4278 92.8541 19.2923C91.7241 17.4894 90.7903 15.9984 90.7844 15.9776C90.7755 15.9568 91.6081 14.5876 92.637 12.9362C94.1358 10.5215 94.4956 9.92448 94.4719 9.8829C94.4451 9.84131 94.3291 9.83537 93.3656 9.83537H92.2861L92.2088 9.92448C92.1642 9.97497 91.507 11.0591 90.7457 12.3392C89.9874 13.6164 89.357 14.6619 89.3481 14.6619C89.3362 14.6619 88.676 13.5985 87.8731 12.2976C86.8114 10.569 86.3951 9.91557 86.3297 9.87696C86.2464 9.82349 86.1602 9.82052 85.0629 9.82052C84.0726 9.82052 83.8793 9.82646 83.8496 9.86211Z"
        fill="#4D4D4D"
      />
      <path
        d="M95.4482 9.84702C95.4244 9.86187 95.4125 9.90643 95.4214 9.95692C95.4303 10.0044 96.5693 12.8884 97.958 16.3695C99.3438 19.8505 100.48 22.7166 100.477 22.7404C100.477 22.7642 100.159 23.6938 99.775 24.8017C99.3884 25.9125 99.0732 26.8303 99.0732 26.8422C99.0732 26.8541 99.094 26.8867 99.1208 26.9105C99.1594 26.9521 99.2962 26.958 100.144 26.958C101.116 26.958 101.122 26.958 101.196 26.8897C101.256 26.8392 101.866 25.1165 104.215 18.401C105.836 13.7706 107.162 9.95692 107.162 9.93019C107.162 9.82623 107.064 9.81732 105.972 9.82623C105.033 9.83514 104.899 9.84108 104.854 9.88267C104.819 9.92128 103.439 14.1923 101.821 19.2921C101.732 19.5713 101.675 19.7079 101.66 19.6782C101.648 19.6544 100.822 17.4773 99.8226 14.8398C98.8264 12.2023 97.9937 10.0074 97.9729 9.96583C97.9521 9.92128 97.9045 9.87078 97.8629 9.85296C97.7766 9.81435 95.5106 9.80841 95.4482 9.84702Z"
        fill="#4D4D4D"
      />
    </Icon>
  );
};
