import { getColors } from './colors';
import { GridConfig, SpacingConfig, ThemeInterface, ThemeName } from './types';

/** grid */
export const grid: GridConfig = {
  outerMargin: 32,
  gutter: 8,
  totalCols: 12,
  container: {
    xs: 300,
    sm: 768,
    md: 1024,
    lg: 1280,
  },
  breakpoints: {
    xs: 0,
    sm: 300,
    md: 768,
    lg: 1024,
  },
};

/** spacing */
export const spacing: SpacingConfig = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
};

export const getTheme = (themeName: ThemeName): ThemeInterface => {
  const colors = getColors(themeName);

  return {
    colors,
    name: themeName,
    grid,
    spacing,
  };
};
