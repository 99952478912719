import afcWimbledon from './business-assets/afc-wimbledon.png';
import beacons from './business-assets/beacons.png';
import bobbie from './business-assets/bobbie.png';
import cere from './business-assets/cere.png';
import customerIo from './business-assets/customer-io.png';
import drinkLmlt from './business-assets/drink-lmnt.png';
import feminvest from './business-assets/feminvest.png';
import firefly from './business-assets/firefly.png';
import klarna from './business-assets/klarna.png';
import maven from './business-assets/maven.png';
import personalAi from './business-assets/personal-ai.png';
import robinhood from './business-assets/robinhood.png';
import skybound from './business-assets/skybound.png';
import spaceX from './business-assets/space-x.png';
import starAtlas from './business-assets/star-atlas.png';
import terraformation from './business-assets/terraformation.png';
import thisLogo from './business-assets/this.png';
import truthArts from './business-assets/truth-arts.png';

export interface IBusiness {
  title: string;
  logo: string;
  description: string;
}

export const businesses: IBusiness[] = [
  {
    title: 'SpaceX',
    description: 'Advanced rockets and spacecraft',
    logo: spaceX,
  },
  {
    title: 'Personal AI',
    description: 'Private models trained on memory',
    logo: personalAi,
  },
  {
    title: 'Drink LMLT',
    description: 'Restoring health through hydration',
    logo: drinkLmlt,
  },
  {
    title: 'Skybound',
    description: 'Global hit entertainment company',
    logo: skybound,
  },
  {
    title: 'Bobbie',
    description: 'Changing the formula conversation ',
    logo: bobbie,
  },
  {
    title: 'Firefly Aerospace',
    description: 'Enabling our world to launch',
    logo: firefly,
  },
  {
    title: 'AFC Wimbledon',
    description: 'English football club',
    logo: afcWimbledon,
  },
  {
    title: 'Customer.io',
    description: 'Engage and retain customers',
    logo: customerIo,
  },
  {
    title: 'Star Atlas',
    description: 'Space exploration strategy game',
    logo: starAtlas,
  },
  {
    title: 'Robinhood',
    description: 'Financial services company',
    logo: robinhood,
  },
  {
    title: 'Cere Network',
    description: 'Decentralized data and finance cloud',
    logo: cere,
  },
  {
    title: 'Truth Arts',
    description: 'Creative mutant world-builders',
    logo: truthArts,
  },
  {
    title: 'This',
    description: 'Meaty plant-based alternatives',
    logo: thisLogo,
  },
  {
    title: 'Terraformation',
    description: 'Hyperscaling forest restoration',
    logo: terraformation,
  },
  {
    title: 'Klarna',
    description: 'E-commerce payment processing',
    logo: klarna,
  },
  {
    title: 'Beacons',
    description: 'All-in-one creator platform',
    logo: beacons,
  },
  {
    title: 'Maven',
    description: 'Learn from experts in your field',
    logo: maven,
  },
  {
    title: 'Feminvest',
    description: 'Largest hub for female entrepreneurs',
    logo: feminvest,
  },
];
