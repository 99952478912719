import React from 'react';

import { useIsomorphicLayoutEffect } from '../../../utils/use-isomorphic-layout-effect';
import { useLazyImage } from '../../../utils/use-lazy-image';
import { CarouselItemWrapper } from '../carousel-item-wrapper';
import {
  CarouselCol,
  CarouselGrid,
  CarouselGridSecondaryMarket,
  CarouselRow,
  Styles,
} from './carousel-item';
import {
  PITCH_THEME,
  SECONDARY_MARKET_THEME,
  SEEDRS_PRODUCT_THEME,
} from './constants';
import { PitchCarouselItem } from './pitch-carousel-item';
import { SecondaryMarketCarouselItem } from './secondary-market-carousel-item';
import { SeedrsProductCarouselItem } from './seedrs-product-carousel-item';
import { IWrapperProps } from './types';

export const CarouselItem: React.FC<React.PropsWithChildren<IWrapperProps>> = ({
  itemId,
  position,
  backgroundDesktopImage,
  backgroundMobileImage,
  foregroundDesktopImage,
  foregroundMobileImage,
  foregroundComponent,
  logo,
  overlayTheme = 'light',
  overlayColor,
  overlayOpacity,
  ctaText,
  ctaTheme,
  description,
  link = '#',
  linkNewTab = false,
  pillText,
  title = null,
  logoAlt,
  disclaimer,
  theme,
  height,
  centeredContent = false,
  carouselItemSeen,
  carouselItemCTAClicked,
  desktopHeight,
  ctaSize,
  eagerImages,
  secondaryMarketStatus,
}) => {
  const [lazyDesktopSrc, setLazyDesktopSrc] = useLazyImage(
    backgroundDesktopImage ?? ''
  );
  const [lazyMobileSrc, setLazyMobileSrc] = useLazyImage(
    backgroundMobileImage ?? ''
  );
  const [lazyLogoSrc, setLazyLogoSrc] = useLazyImage(logo || '');

  useIsomorphicLayoutEffect(() => {
    setLazyDesktopSrc(backgroundDesktopImage ?? '');
    setLazyMobileSrc(backgroundMobileImage ?? '');
    setLazyLogoSrc(logo || '');
  }, [backgroundDesktopImage, backgroundMobileImage, logo]);

  const desktopSrc = eagerImages ? backgroundDesktopImage : lazyDesktopSrc;
  const mobileSrc = eagerImages ? backgroundMobileImage : lazyMobileSrc;
  const logoSrc = eagerImages ? logo : lazyLogoSrc;

  const carouselColMdSize: number = theme === SECONDARY_MARKET_THEME ? 12 : 6;
  const isSecondaryMarketTheme =
    theme === SECONDARY_MARKET_THEME && secondaryMarketStatus;
  const Grid = isSecondaryMarketTheme
    ? CarouselGridSecondaryMarket
    : CarouselGrid;

  return (
    <CarouselItemWrapper desktopHeight={desktopHeight} height={height}>
      <Styles.Overlay
        overlayColor={overlayColor}
        overlayOpacity={overlayOpacity}
        overlayTheme={overlayTheme}
      />
      <Styles.OverlayEnd
        overlayColor={overlayColor}
        overlayOpacity={overlayOpacity}
        overlayTheme={overlayTheme}
      />
      <Grid>
        <CarouselRow>
          <CarouselCol xs={12} md={carouselColMdSize}>
            {theme === SEEDRS_PRODUCT_THEME && (
              <SeedrsProductCarouselItem
                itemId={itemId}
                position={position}
                backgroundDesktopImage={desktopSrc}
                overlayTheme={overlayTheme}
                ctaText={ctaText}
                ctaSize={ctaSize}
                ctaTheme={ctaTheme}
                description={description}
                link={link}
                linkNewTab={linkNewTab}
                title={title}
                disclaimer={disclaimer}
                theme={theme}
                centeredContent={centeredContent}
                carouselItemSeen={carouselItemSeen}
                carouselItemCTAClicked={carouselItemCTAClicked}
              />
            )}
            {theme === PITCH_THEME && (
              <PitchCarouselItem
                itemId={itemId}
                position={position}
                backgroundDesktopImage={desktopSrc}
                overlayTheme={overlayTheme}
                ctaText={ctaText}
                ctaTheme={ctaTheme}
                description={description}
                link={link}
                linkNewTab={linkNewTab}
                title={title}
                theme={theme}
                centeredContent={centeredContent}
                carouselItemSeen={carouselItemSeen}
                carouselItemCTAClicked={carouselItemCTAClicked}
                logo={logoSrc}
                pillText={pillText}
                logoAlt={logoAlt}
                ctaSize={ctaSize}
              />
            )}
            {isSecondaryMarketTheme && (
              <SecondaryMarketCarouselItem
                itemId={itemId}
                position={position}
                backgroundDesktopImage={desktopSrc}
                overlayTheme={overlayTheme}
                ctaText={ctaText}
                ctaTheme={ctaTheme}
                description={description}
                link={link}
                linkNewTab={linkNewTab}
                title={title}
                theme={theme}
                centeredContent={centeredContent}
                carouselItemSeen={carouselItemSeen}
                carouselItemCTAClicked={carouselItemCTAClicked}
                secondaryMarketStatus={secondaryMarketStatus}
              />
            )}
          </CarouselCol>
          {foregroundComponent && (
            <CarouselCol xs={12} md={6}>
              <Styles.ForegroundComponentWrapper>
                {foregroundComponent}
              </Styles.ForegroundComponentWrapper>
            </CarouselCol>
          )}
          {(foregroundDesktopImage || foregroundMobileImage) && (
            <CarouselCol xs={12} md={6}>
              <Styles.ForegroundImageWrapper>
                <Styles.ForegroundImage
                  foregroundDesktopImage={foregroundDesktopImage}
                  foregroundMobileImage={foregroundMobileImage}
                />
              </Styles.ForegroundImageWrapper>
            </CarouselCol>
          )}
        </CarouselRow>
      </Grid>
      <Styles.BackgroundImageWrapper>
        <Styles.BackgroundImage
          backgroundDesktopImage={desktopSrc}
          backgroundMobileImage={mobileSrc}
        />
      </Styles.BackgroundImageWrapper>
    </CarouselItemWrapper>
  );
};
