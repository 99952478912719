import { FC, ReactNode } from 'react';

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  IconProps,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';

import { useMobile } from './use-mobile';

export interface ISectionProps {
  icon: FC<IconProps>;
  title: ReactNode;
  children: ReactNode;
  desktopColumns?: number;
}

export const Section = ({
  desktopColumns,
  children,
  title,
  icon,
}: ISectionProps) => {
  const isMobile = useMobile();
  const Icon = icon;
  const heading = (
    <Heading size={['republic-h5', 'republic-h4']} my="8">
      <Icon mr="1" boxSize="8" /> {title}
    </Heading>
  );

  if (isMobile) {
    return (
      <AccordionItem borderBottom="none" mx="-4">
        {({ isExpanded }) => (
          <>
            {!isExpanded && <Divider color="grey.100" />}
            <AccordionButton
              p="0"
              px="4"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {heading}
              <AccordionIcon boxSize="6" />
            </AccordionButton>
            <AccordionPanel px="0" py="8" mx="4">
              <VStack spacing={{ base: 4, md: 8 }}>{children}</VStack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }

  return (
    <Box mb="16" _last={{ mb: '0' }}>
      <Divider color="grey.100" />

      {heading}

      <SimpleGrid columns={[1, desktopColumns ?? 2]} spacing="4">
        {children}
      </SimpleGrid>
    </Box>
  );
};
