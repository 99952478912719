import { observer } from 'mobx-react-lite';

import {
  INextSEOMetaTagsProps,
  NextSEOMetaTags,
} from '@r-client/shared/util/seo';

type IPageSEOMetaProps = Partial<
  Pick<
    INextSEOMetaTagsProps,
    | 'title'
    | 'metaTitle'
    | 'description'
    | 'metaDescription'
    | 'openGraph'
    | 'social'
  >
> & {
  url: string;
  image: string;
  hideSiteName?: boolean;
  canonicalUrl?: string;
};

export const DEFAULT_TITLE = 'Republic — where anyone can invest in startups';
export const DEFAULT_DESCRIPTION =
  'Invest in impactful startups raising money via equity crowdfunding';

export const PageSEOMeta = observer<IPageSEOMetaProps>(function PageSEOMeta({
  url,
  social,
  openGraph,
  title: titleProp,
  hideSiteName = false,
  metaTitle: metaTitleProp,
  description: descriptionProp,
  image,
  canonicalUrl,
}) {
  const title = titleProp
    ? `${titleProp}${hideSiteName ? '' : ' — Republic'}`
    : DEFAULT_TITLE;
  const metaTitle = metaTitleProp ? metaTitleProp : title;
  const description = descriptionProp || DEFAULT_DESCRIPTION;

  return (
    <NextSEOMetaTags
      title={title}
      metaTitle={metaTitle}
      description={description}
      image={image}
      openGraph={
        openGraph || {
          url,
          image,
          description,
          type: 'website',
          title: metaTitle,
          locale: 'en_US',
        }
      }
      social={{
        twitter: social?.twitter || {
          image,
          imageAlt: '',
          description,
          card: 'summary_large_image',
          site: '@joinrepublic',
          title: metaTitle,
          creator: '@joinrepublic',
        },
      }}
    >
      {/* Facebook ID */}
      <meta content="1333788929968275" property="fb:app_id" />
      {/* Google verification ID */}
      <meta
        content="c7HkWkIit50eeEjD4gcVAXX6ANIaDtO43yauW3fICgI"
        name="google-site-verification"
      />
      {/* Canonical URL */}
      {!!canonicalUrl && <link href={canonicalUrl} rel="canonical" />}
    </NextSEOMetaTags>
  );
});
