import { CSSProperties } from 'react';
import { useEffect, useState } from 'react';

import { Box, useMediaQuery } from '@chakra-ui/react';
import { Pagination, Swiper as ISwiper } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { theme } from '@r-client/republic/ui/theme';
import { useAnalytics } from '@r-client/shared/data/analytics';

import { ISlide } from '../data/types';
import { Slide } from './slide';

import styles from './slider.module.scss';

export interface ISliderProps {
  tabsWidth?: number;
  activeSlide: number;
  onSliderChange: (index: number) => void;
  slides: ISlide[];
}

export const Slider = ({
  slides,
  tabsWidth,
  activeSlide,
  onSliderChange,
}: ISliderProps) => {
  const analytics = useAnalytics();
  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [isLargerThanSm] = useMediaQuery(`(min-width: 768px)`, { ssr: true });
  const [isLargerThanLg] = useMediaQuery(
    `(min-width: ${theme.breakpoints.lg})`,
    {
      ssr: true,
    }
  );

  useEffect(() => {
    swiper && swiper.slideTo(activeSlide);
  }, [activeSlide]);

  const dots = {
    clickable: true,
    renderBullet: (_index: number, className: string) =>
      `<div class="${className}" ></div>`,
  };

  const slideWidth = {
    '--slide-width': `${Number(tabsWidth) - 24}px`,
  } as CSSProperties;

  function getSpaceBetween() {
    if (isLargerThanSm && !isLargerThanLg) return 30;
    if (isLargerThanLg) return 63;
    return 18;
  }

  function handleSlideChange(swiperInstance: ISwiper) {
    onSliderChange(swiperInstance.activeIndex);
  }

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    return window.removeEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setOffset((width - Number(tabsWidth)) / 2);
  }, [tabsWidth, width]);

  return (
    <Box style={slideWidth}>
      <Swiper
        pagination={dots}
        modules={[Pagination]}
        className={styles.slider}
        spaceBetween={getSpaceBetween()}
        slidesPerView="auto"
        slidesOffsetBefore={offset}
        slidesOffsetAfter={
          offset +
          Number(tabsWidth) -
          Number(
            swiper && swiper.slides?.length ? swiper.slides[1].clientWidth : 0
          )
        }
        onSlideChange={handleSlideChange}
        onSwiper={setSwiper}
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide
              key={slide.name}
              className={styles.slide}
              onClick={() => {
                analytics?.track({
                  name: 'click_view_case_study',
                  params: {
                    label: slide.name.toLowerCase().replace(' ', '_'),
                  },
                });
              }}
            >
              <Slide slide={slide} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
