import { Box, BoxProps, Link, Text } from '@chakra-ui/react';

export interface ICardButtonProps extends BoxProps {
  title: string;
  description: string;
  href?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  isExternal?: boolean;
}

export const CardButton = ({
  title,
  description,
  isDisabled,
  href,
  onClick,
  isExternal = false,
}: ICardButtonProps) => {
  return (
    <Box
      role="button"
      onClick={onClick}
      as="button"
      display="inline-flex"
      flexDirection="column"
      border="1px solid"
      borderColor="grey.200"
      borderRadius="2xl"
      minHeight="7rem"
      width="100%"
      background="white"
      _hover={{
        color: 'blue.500',
        borderColor: 'blue.500',
        p: {
          color: 'blue.500',
        },
      }}
      _active={{
        color: 'blue.800',
        borderColor: 'blue.800',
        p: {
          color: 'blue.800',
        },
      }}
      _disabled={{
        color: 'grey.400',
        borderColor: 'grey.400',
        p: {
          color: 'grey.400',
        },
      }}
      disabled={isDisabled}
      textAlign="left"
      aria-label={title}
    >
      <Link
        href={href}
        aria-label={title}
        isExternal={isExternal}
        _hover={{ textDecor: 'none' }}
        display="flex"
        h="100%"
        w="100%"
        flexDirection="column"
        justifyContent="space-between"
        p="4"
      >
        <Text textStyle="md-bold" mb="4">
          {title}
        </Text>
        <Text textStyle="sm" color="grey.600">
          {description}
        </Text>
      </Link>
    </Box>
  );
};
