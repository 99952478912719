import { useEffect } from 'react';

import Script from 'next/script';

import { getWindow } from '@r-client/shared/util/core';

type IZendeskScriptProps = {
  email?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  zendeskKey: string | undefined;
};

const settings = {
  webWidget: {
    zIndex: 101,
    offset: {
      horizontal: '55px',
      vertical: '2px',
      mobile: {
        horizontal: '-4px',
        vertical: '#{mobile_offset}',
      },
    },
    contactForm: {
      title: { '*': 'How can we help?' },
      ticketForms: [{ id: 6139814921239 }],
      fields: [
        { id: 'subject', prefill: { '*': 'Need help!' } },
        { id: 'email', prefill: { '*': '' } },
        {
          id: 'key:6164328823703',
          prefill: { '*': '' },
        },
      ],
    },
    helpCenter: {
      title: { '*': 'FAQ' },
      searchPlaceholder: { '*': 'What can we help you with?' },
      messageButton: { '*': 'Contact Us' },
      originalArticleButton: false,
    },
  },
};

export const ZendeskScript = ({
  email,
  firstName,
  lastName,
  zendeskKey,
}: IZendeskScriptProps) => {
  const zE = getWindow<{ zE: any }>()?.zE;

  const onReady = () => {
    try {
      getWindow<{ zE: any }>()?.zE?.('webWidget', 'updateSettings', settings);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (!zE) return;
    settings.webWidget.contactForm.fields = [
      { id: 'subject', prefill: { '*': 'Need help!' } },
      { id: 'email', prefill: { '*': email ?? '' } },
      {
        id: 'key:6164328823703',
        prefill: {
          '*': firstName && lastName ? `${firstName} ${lastName}` : '',
        },
      },
    ];
    zE('webWidget', 'updateSettings', settings);
  }, [email, firstName, lastName, zE]);

  return (
    <Script
      id="ze-snippet"
      onReady={onReady}
      src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`}
      strategy="beforeInteractive"
    />
  );
};
