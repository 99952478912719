import { ColorsConfig } from '../../theme/types';

export const common: Omit<ColorsConfig, 'primary' | 'secondary'> = {
  green: {
    xlight: '#E5F1D5',
    light: '#CCE4AC',
    midlight: '#B2D683',
    base: '#99C95A',
    middark: '#7FBB30',
    dark: '#6FA32B',
    xdark: '#5C8723',
  },
  blue: {
    xlight: '#B1E0FF',
    light: '#5FBFFF',
    midlight: '#2486FF',
    base: '#1461FF',
    middark: '#0049FF',
    dark: '#002EFF',
    xdark: '#002F99',
  },
  purple: {
    xlight: '#EAEBF2',
    light: '#CBCCE0',
    midlight: '#AAABCB',
    base: '#7270A6',
    middark: '#56508E',
    dark: '#453B75',
    xdark: '#372B5F',
  },
  cherry: {
    xlight: '#FFD9E6',
    light: '#FFAAC8',
    midlight: '#FF76A7',
    base: '#F94B89',
    middark: '#D63670',
    dark: '#BA1D56',
    xdark: '#9B1143',
  },
  teal: {
    xlight: '#F0F9F9',
    light: '#B1E6E6',
    midlight: '#82D8D8',
    base: '#47C1C1',
    middark: '#27A3A3',
    dark: '#0D7C7C',
    xdark: '#035959',
  },
  neutral: {
    xlight: '#F9FAFB',
    light: '#F4F6F8',
    midlight: '#DFE3E8',
    base: '#C4CDD5',
    middark: '#637381',
    dark: '#454F5B',
    xdark: '#212B36',
  },
  yellow: {
    xlight: '#FFF1CF',
    light: '#FFE39F',
    midlight: '#FFD570',
    base: '#FFC021',
    middark: '#FFAB00',
    dark: '#EB9E00',
    xdark: '#D89100',
  },
  red: {
    xlight: '#FFDAD3',
    light: '#FFB5A7',
    midlight: '#FF947F',
    base: '#FF6B4E',
    middark: '#FF4622',
    dark: '#CC381B',
    xdark: '#992A14',
  },
};
