import { Flex, Link, SimpleGrid, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { Apple, Playstore } from '@r-client/shared/ui/icons';

import { IStatBoxProps, StatBox } from '../stat-box/stat-box';
import republicQrCode from './republic_qr_code.png';
import seedrsQrCode from './seedrs_qr_code.png';

export interface IStatsStatelessProps {
  stats: IStatBoxProps[];
  region: string;
}

export const StatsStateless = ({ stats, region }: IStatsStatelessProps) => {
  const showAppLink = region !== 'global';

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <SimpleGrid
        columns={{ base: 2, md: stats.length }}
        alignItems={{ base: 'center', md: 'stretch' }}
        spacing={{ base: '8', md: '0' }}
      >
        {stats.map((stat, index) => (
          <Flex
            flex={{ base: '1 50%', md: '20%' }}
            justifyContent={{ base: 'center', md: 'start' }}
            alignItems={{ md: 'start' }}
            key={index}
            borderRight={{
              base: 'none',
              md:
                stats.length > index + 1 || showAppLink ? '1px solid' : 'none',
            }}
            borderColor={{ base: 'transparent', md: 'grey.50' }}
            px={{ md: '4' }}
            _first={{ pl: 0 }}
          >
            <StatBox {...stat} />
          </Flex>
        ))}
      </SimpleGrid>
      {showAppLink && (
        <Flex
          paddingTop={{ base: '8', md: '0' }}
          direction="row"
          gap="1.5"
          alignItems="center"
          justifyContent="center"
          pl={{ md: '4', lg: '6' }}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            minW="44px"
            minH="44px"
          >
            <Link
              href="https://apps.apple.com/us/app/republic-invest-in-startups/id1475230022"
              isExternal={true}
              aria-label="iOS Republic App"
            >
              <Apple />
            </Link>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            minW="44px"
            minH="44px"
          >
            <Link
              href="https://play.google.com/store/apps/details?id=com.republicmobile&hl=en"
              isExternal={true}
              aria-label="Android Republic App"
            >
              <Playstore />
            </Link>
          </Flex>
          <Flex alignItems="center" justifyContent="center" gap="2">
            <Flex minW="36px" minH="36px" justifyContent="center">
              <Image
                width={36}
                height={36}
                loader={({ src: imageSrc }) => {
                  return imageSrc;
                }}
                src={region === 'europe' ? seedrsQrCode : republicQrCode}
                alt="qr_code"
                unoptimized
              />
            </Flex>
            <Text fontSize="xs" color="grey.900" lineHeight="16px">
              Scan to download
              <br />
              the app
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
