import { Icon, IconProps } from '@chakra-ui/react';

export const Valor = (props: IconProps) => {
  return (
    <Icon width="113" height="30" viewBox="0 0 113 30" fill="none" {...props}>
      <path
        d="M40.13 29.6136H42.0363V29.3537H40.508V28.1678H41.8884V27.9079H40.508V26.7221H42.0363V26.4622H40.13V29.6136Z"
        fill="#4D4D4D"
      />
      <path
        d="M47.2524 28.0373C47.2524 27.0597 46.5129 26.4042 45.6255 26.4205C44.7381 26.4367 44.0643 27.067 44.0643 28.1023C44.0643 29.0278 44.7874 29.6618 45.6748 29.6455C46.0199 29.6455 46.365 29.5318 46.6444 29.3369L47.1538 29.8405L47.351 29.6455L46.858 29.1582C47.1209 28.8496 47.2688 28.4435 47.2524 28.0373ZM45.6584 29.3531C44.9189 29.4019 44.3569 28.7727 44.3569 28.0373C44.3569 27.302 44.9024 26.6891 45.6255 26.6891C46.3486 26.6891 46.8744 27.2089 46.9237 27.9399C46.9237 27.9724 46.9237 27.9886 46.9237 28.0211C46.9402 28.346 46.8251 28.6546 46.6279 28.9145L46.2171 28.5084L46.0199 28.7034L46.4307 29.1095C46.2171 29.2719 45.9377 29.3531 45.6584 29.3531Z"
        fill="#4D4D4D"
      />
      <path
        d="M89.1191 29.6131H91.0418V29.3532H89.4971V28.1673H90.8775V27.9074H89.4971V26.7216H91.0418V26.4617H89.1191V29.6131Z"
        fill="#4D4D4D"
      />
      <path
        d="M97.4756 27.2572C97.4756 26.9485 97.7221 26.6886 98.0344 26.6886C98.0672 26.6886 98.1165 26.6886 98.1494 26.7049C98.4616 26.6724 98.7574 26.8836 98.8232 27.1922H99.1518C99.0861 26.7211 98.6424 26.38 98.1658 26.4287C97.6728 26.38 97.2456 26.7211 97.1798 27.1922C97.1798 27.2084 97.1798 27.2409 97.1798 27.2734C97.1798 28.4593 98.8725 27.8745 98.8725 28.8004C98.8542 29.1624 98.5328 29.369 98.1987 29.369C97.8646 29.369 97.5249 29.1578 97.4756 28.8166H97.147C97.1844 29.3907 97.682 29.6614 98.1987 29.6614C98.6949 29.6614 99.1354 29.3527 99.1847 28.8654C99.1847 28.8491 99.1847 28.8329 99.1847 28.8004C99.1683 27.6146 97.4756 28.1669 97.4756 27.2572Z"
        fill="#4D4D4D"
      />
      <path
        d="M51.1969 28.4599C51.2273 28.9634 50.8771 29.402 50.4204 29.4345C49.9484 29.467 49.5373 29.0934 49.5068 28.6061C49.5068 28.5573 49.5068 28.5086 49.5068 28.4599V26.478H49.1871V28.4599C49.1414 29.0771 49.5677 29.6132 50.1463 29.6619C50.2224 29.6619 50.2986 29.6619 50.3747 29.6619C50.9533 29.7432 51.4709 29.3208 51.5623 28.7198C51.5775 28.6385 51.5775 28.5573 51.5623 28.4599V26.478H51.1969V28.4599Z"
        fill="#4D4D4D"
      />
      <path d="M54.4961 26.478H54.151V29.6295H54.4961V26.478Z" fill="#4D4D4D" />
      <path
        d="M56.5576 26.7216H57.4943V29.6131H57.823V26.7216H58.7433V26.4617H56.5576V26.7216Z"
        fill="#4D4D4D"
      />
      <path
        d="M80.0577 26.7216H80.9944V29.6131H81.3231V26.7216H82.2598V26.4617H80.0577V26.7216Z"
        fill="#4D4D4D"
      />
      <path
        d="M86.5444 29.1095L84.6874 26.4617H84.3588V29.6131H84.6874V26.9652L86.5444 29.6131H86.8731V26.4617H86.5444V29.1095Z"
        fill="#4D4D4D"
      />
      <path
        d="M61.7841 28.1024L60.9131 26.4617H60.5352L61.6198 28.3948V29.6131H61.9484V28.3948L63.033 26.4617H62.6551L61.7841 28.1024Z"
        fill="#4D4D4D"
      />
      <path
        d="M68.8685 26.478H67.9482V29.6295H68.2605V28.2812H68.8685C69.5751 28.2812 69.9038 27.8913 69.9038 27.3877C69.9038 26.8679 69.5751 26.478 68.8685 26.478ZM68.8685 28.005H68.2605V26.7379H68.8685C69.3779 26.7379 69.5751 26.9654 69.5751 27.3877C69.5916 27.7776 69.3779 28.005 68.8685 28.005Z"
        fill="#4D4D4D"
      />
      <path
        d="M72.6494 26.5103L71.4169 29.6129H71.7784L72.0742 28.8657H73.6189L73.9147 29.6129H74.2763L73.0438 26.5103H72.6494ZM72.1728 28.6058L72.8466 26.9326L73.5203 28.6058H72.1728Z"
        fill="#4D4D4D"
      />
      <path
        d="M78.1323 27.3579C78.1323 26.838 77.856 26.478 77.0837 26.478H76.2424V29.6295H76.5875V28.2974H77.0056L77.8437 29.6132H78.2053L77.3343 28.2812C77.4154 28.2812 78.1323 28.0408 78.1323 27.3579ZM76.5875 27.9851V26.7379H77.0837C77.6424 26.7379 77.8653 26.9518 77.8653 27.3579C77.8653 27.7119 77.5521 27.9851 77.2262 27.9851H76.5875Z"
        fill="#4D4D4D"
      />
      <path
        d="M95.055 27.3579C95.055 26.838 94.7788 26.478 94.0064 26.478H93.1652V29.6295H93.5103V28.2974H93.9284L94.7665 29.6132H95.128L94.257 28.2812C94.3381 28.2812 95.055 28.0408 95.055 27.3579ZM93.5103 27.9851V26.7379H94.0064C94.5651 26.7379 94.788 26.9518 94.788 27.3579C94.788 27.7119 94.4748 27.9851 94.1489 27.9851H93.5103Z"
        fill="#4D4D4D"
      />
      <path
        d="M40.8457 0.195801L34.9885 14.526L29.116 0.195801H26.5179L34.9731 20.8629L34.9885 20.8781L35.0039 20.8325L43.4437 0.195801H40.8457Z"
        fill="#4D4D4D"
      />
      <path
        d="M51.1349 0.211182H49.0595V0.256774L40.7581 20.8783H43.3561L45.5852 15.2404H54.5938L56.8229 20.8479H59.421L51.1503 0.241576L51.1349 0.211182ZM46.523 12.9306L50.1049 3.9343L53.6714 12.9306H46.523Z"
        fill="#4D4D4D"
      />
      <path
        d="M65.4674 0.195801H63.1154V18.553V19.4496V20.8781H75.0295V18.553H65.4674V0.195801Z"
        fill="#4D4D4D"
      />
      <path
        d="M109.643 20.8782H112.81L107.06 12.3378C110.412 11.654 112.579 8.41712 111.887 5.08912C111.288 2.2322 108.751 0.180688 105.8 0.195885H99.7581V20.8782H102.11V12.4594H104.17L109.643 20.8782ZM102.126 2.53613H105.815C107.937 2.53613 109.643 4.22292 109.643 6.32002C109.643 8.41712 107.937 10.1039 105.815 10.1039H102.126V2.53613Z"
        fill="#4D4D4D"
      />
      <path
        d="M86.1897 0.195801C80.6554 0.195801 76.1204 4.8155 76.1204 10.5597C76.1204 16.304 80.6247 20.8933 86.1897 20.8933C91.7548 20.8933 96.2591 16.2584 96.2591 10.5597C96.2591 4.86109 91.7394 0.195801 86.1897 0.195801ZM86.1897 18.629C82.0697 18.629 78.7184 15.0275 78.7184 10.5749C78.7184 6.12239 82.0697 2.52085 86.1897 2.52085C90.3097 2.52085 93.661 6.12239 93.661 10.5749C93.661 15.0275 90.3097 18.629 86.1897 18.629Z"
        fill="#4D4D4D"
      />
      <path
        d="M10.5421 10.3939V10.3819L15.061 0.195801H13.1L10.5421 6.37248L7.98425 0.195801H6.0354L10.5421 10.3819V10.3939Z"
        fill="#4D4D4D"
      />
      <path
        d="M10.5421 10.7065V10.7186L6.0354 20.8926H7.98425L10.5421 14.728L13.1 20.8926H15.061L10.5421 10.7186V10.7065Z"
        fill="#4D4D4D"
      />
      <path
        d="M10.3837 10.5502H10.3716L0.0670166 6.08325V8.02174L6.3277 10.5502L0.0670166 13.0787V15.0051L10.3716 10.5502H10.3837Z"
        fill="#4D4D4D"
      />
      <path
        d="M21.0171 6.08325L10.7125 10.5502L21.0171 15.0051V13.0787L14.7686 10.5502L21.0171 8.02174V6.08325Z"
        fill="#4D4D4D"
      />
    </Icon>
  );
};
