/* eslint-disable max-lines */

import React, { useEffect } from 'react';

import { ArrowIcon } from '../arrow-icon';
import { CarouselTrack } from '../carousel-track';
import { IndicatorsColor } from '../types';
import { getRowLayout, positiveModulo, range } from './helper';
import {
  CarouselWrapper,
  Dot,
  Dots,
  IndicatorArrow,
  ItemsContainer,
  RowsPerSlide,
  Slide,
} from './style';

const SPEED = 500;

interface CarouselProps {
  container?: React.ComponentType<React.PropsWithChildren<unknown>>;
  rowsPerSlide?: RowsPerSlide;
  autoplay?: boolean;
  autoplaySpeed?: number;
  pauseOnHover?: boolean;
  wrapAround?: boolean;
  showArrows?: boolean;
  showIndicators?: boolean;
  indicatorsColor?: IndicatorsColor;
  dotsOverBanner?: boolean;
  dataAutomationId?: string;
  isHomepageHeader?: boolean;
}

export const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({
  container: ContainerProp = React.Fragment,
  rowsPerSlide = {},
  autoplay = false,
  autoplaySpeed = 5000,
  pauseOnHover = true,
  wrapAround = false,
  showArrows = false,
  showIndicators = true,
  indicatorsColor = 'blue',
  dotsOverBanner = false,
  dataAutomationId,
  children,
  isHomepageHeader = false,
}) => {
  const [loaded, setLoaded] = React.useState(false);
  // Array of row numbers for child elements
  const [rows, setRows] = React.useState<number[]>([]);
  // Number of slides (calculated from rows)
  const [numSlides, setNumSlides] = React.useState(0);
  // Current slide
  const [index, setIndex] = React.useState(0);
  // Current animation state (negative moving left, zero not animating, positive moving right)
  const [animateRelative, setAnimateRelative] = React.useState(0);

  // Ref to hidden container of all children
  const containerRef = React.useRef<HTMLDivElement>(null);

  // The rowsPerSlide config is written into a media query and the current
  // value is read on resize
  const [currentRowsPerSlide, setCurrentRowsPerSlide] = React.useState(
    rowsPerSlide.md || 1
  );

  const getCurrentRowsPerSlide = React.useCallback(() => {
    if (containerRef.current) {
      const currentOrder = window.getComputedStyle(containerRef.current).order;

      setCurrentRowsPerSlide(
        (currentOrder && parseInt(currentOrder, 10)) || rowsPerSlide.md || 1
      );
    }
  }, [rowsPerSlide.md]);

  useEffect(() => {
    getCurrentRowsPerSlide();
  }, [rowsPerSlide.xs, rowsPerSlide.sm, rowsPerSlide.md, rowsPerSlide.lg]);

  // Update number of slides and current slide when row layout changes
  useEffect(() => {
    const numRows = rows.length && rows[rows.length - 1] + 1;
    const numSlidesUpdated = Math.ceil(numRows / currentRowsPerSlide);
    setNumSlides(numSlidesUpdated);
    setIndex((i) => Math.max(0, Math.min(i, numSlidesUpdated - 1)));
  }, [rows, currentRowsPerSlide]);

  // Only update current slide index when animation completes
  const animationCallback = React.useCallback(() => {
    setIndex((i) => positiveModulo(i + animateRelative, numSlides));
    setAnimateRelative(0);
  }, [animateRelative, numSlides]);

  // When wrapAround is true, move whichever direction gets to the slide fastest
  // Use a ref so the autoplay interval always calls the current version
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const animateWithWrap = React.useRef<(diff: number) => void>(() => {});
  animateWithWrap.current = React.useCallback(
    (diff: number) => {
      if (wrapAround && Math.abs(diff) > numSlides / 2) {
        const sign = diff >= 0 ? 1 : -1;
        diff = diff - sign * numSlides;
      }
      if (!wrapAround) {
        const nextSlide = diff + index;
        if (nextSlide < 0 || nextSlide >= numSlides) {
          diff = positiveModulo(nextSlide, numSlides) - index;
        }
      }
      setAnimateRelative(diff);
    },
    [numSlides, wrapAround, index]
  );

  // Move to next if wrapAround allows
  const next = React.useCallback(() => {
    if (index < numSlides - 1 || wrapAround) {
      setAnimateRelative(1);
    }
  }, [index, numSlides, wrapAround]);

  // Move to previous if wrapAround allows
  const previous = React.useCallback(() => {
    if (index > 0 || wrapAround) {
      setAnimateRelative(-1);
    }
  }, [index, numSlides, wrapAround]);

  // Handle pause on hover
  const [reallyAutoplay, setReallyAutoplay] = React.useState(true);
  const pause = React.useCallback(() => setReallyAutoplay(false), []);
  const resume = React.useCallback(() => setReallyAutoplay(true), []);

  // Recalculate row layout on resize
  const resize = React.useCallback(() => {
    if (containerRef.current) {
      getCurrentRowsPerSlide();

      // Recalculate number of slides
      const rowLayout = getRowLayout(containerRef.current);

      // Only update if layout is different
      if (
        rowLayout.length !== rows.length ||
        rowLayout.some((x, i) => x !== rows[i])
      ) {
        setRows(rowLayout);
      }
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      resize();
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!containerRef.current || !loaded) {
      return;
    }

    const observer = new MutationObserver(resize);
    observer.observe(containerRef.current, {
      childList: true,
      attributes: false,
      subtree: false,
    });

    return () => observer.disconnect();
  }, [loaded, resize]);

  // Autoplay and resize listeners
  useEffect(() => {
    let autoplayTimer: ReturnType<typeof setInterval>;

    const stopAutoplay = () => {
      clearInterval(autoplayTimer);
    };
    const startAutoplay = () => {
      stopAutoplay();
      autoplayTimer = setInterval(
        () => animateWithWrap.current(1),
        autoplaySpeed
      );
    };

    // Recalculate everything on resize
    window.addEventListener('resize', resize, { passive: true });
    if (autoplay && reallyAutoplay) {
      startAutoplay();
      window.addEventListener('focus', startAutoplay);
      window.addEventListener('blur', stopAutoplay);
    }
    return () => {
      window.removeEventListener('resize', resize);
      stopAutoplay();
      window.removeEventListener('focus', startAutoplay);
      window.removeEventListener('blur', stopAutoplay);
    };
  }, [autoplay, reallyAutoplay, autoplaySpeed, resize]);

  const childArray = React.Children.toArray(children);

  return (
    <CarouselWrapper
      isHomepageHeader={isHomepageHeader}
      data-automation={dataAutomationId}
      showDots={showIndicators && !dotsOverBanner}
      onMouseOver={pauseOnHover ? pause : undefined}
      onFocus={pauseOnHover ? pause : undefined}
      onMouseOut={resume}
      onBlur={resume}
    >
      <ContainerProp>
        <ItemsContainer
          ref={containerRef}
          hide={loaded}
          rowsPerSlide={rowsPerSlide}
          isHomepageHeader={isHomepageHeader}
        >
          {children}
        </ItemsContainer>

        <CarouselTrack
          animateRelative={animateRelative}
          next={next}
          previous={previous}
          onComplete={animationCallback}
          speed={SPEED}
        >
          {range(index - numSlides, index + numSlides).map((slideIndex) => {
            // Clone each row of elements into a different slide
            const row = positiveModulo(slideIndex, numSlides);
            return (
              <Slide key={slideIndex} show={wrapAround || slideIndex === row}>
                {childArray
                  .filter(
                    (_child, childIndex) =>
                      Math.floor(rows[childIndex] / currentRowsPerSlide) === row
                  )
                  .filter(React.isValidElement)
                  .map((child) => React.cloneElement(child))}
              </Slide>
            );
          })}
        </CarouselTrack>
      </ContainerProp>

      {showArrows && numSlides > 1 && (
        <>
          {(wrapAround || index > 0) && (
            <IndicatorArrow
              onClick={animateRelative === 0 ? previous : undefined}
              left={true}
              id="previous"
            >
              <ArrowIcon direction="left" color={indicatorsColor} />
            </IndicatorArrow>
          )}
          {(wrapAround || index < numSlides - 1) && (
            <IndicatorArrow
              onClick={animateRelative === 0 ? next : undefined}
              id="next"
            >
              <ArrowIcon direction="right" color={indicatorsColor} />
            </IndicatorArrow>
          )}
        </>
      )}

      {showIndicators && numSlides > 1 && (
        <Dots
          isHomepageHeader={isHomepageHeader}
          overBanner={dotsOverBanner}
          showArrows={showArrows}
        >
          {range(0, numSlides).map((i) => {
            let className = '';

            if (i === index) {
              className = 'active';
            }

            if (!wrapAround && i === index - 1) {
              className = 'previous';
            }

            if (!wrapAround && i === index + 1) {
              className = 'next';
            }

            if (wrapAround && i === positiveModulo(index - 1, numSlides)) {
              className = 'previous';
            }

            if (wrapAround && i === positiveModulo(index + 1, numSlides)) {
              className = 'next';
            }

            return (
              <Dot key={i} color={indicatorsColor} className={className}>
                <button
                  onClick={
                    animateRelative === 0
                      ? () => animateWithWrap.current(i - index)
                      : undefined
                  }
                />
              </Dot>
            );
          })}
        </Dots>
      )}
    </CarouselWrapper>
  );
};
