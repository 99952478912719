import React from 'react';

import { observer } from 'mobx-react-lite';

import { ButtonLink } from '../button-link';
import { InView } from '../in-view';
import { Styles } from './carousel-item';
import { IPitchItemProps } from './types';

const PitchCarouselItemNoMemo = observer(function PitchCarouselItemNoMemo({
  itemId,
  logo,
  overlayTheme,
  ctaText,
  ctaTheme,
  description,
  link,
  linkNewTab,
  pillText,
  logoAlt,
  theme,
  title,
  centeredContent,
  carouselItemSeen,
  carouselItemCTAClicked,
  ctaSize = 'md',
}: IPitchItemProps) {
  return (
    <Styles.ContentWrapper displayTitle={!!title} centered={centeredContent}>
      <InView
        onChange={(value) => {
          if (value && carouselItemSeen) {
            carouselItemSeen(itemId);
          }
        }}
      >
        {logo && logo !== '' && !logo.includes('assets/fallback') && (
          <Styles.LogoWrapper>
            <img src={logo} alt={logoAlt ? logoAlt : 'Banner image'} />
            {pillText && (
              <Styles.Pill show={!title}>
                {pillText.replace(/ /g, '\u00a0')}
              </Styles.Pill>
            )}
          </Styles.LogoWrapper>
        )}
        {title && (
          <Styles.Header
            overlayTheme={overlayTheme}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Styles.DescriptionWrapper itemTheme={theme}>
          <Styles.DescriptionText
            overlayTheme={overlayTheme}
            dangerouslySetInnerHTML={{ __html: description ?? '' }}
          />
        </Styles.DescriptionWrapper>
        <Styles.ActionWrapper
          onClick={() => {
            if (carouselItemCTAClicked) {
              carouselItemCTAClicked(itemId);
            }
          }}
        >
          {ctaText && link && (
            <ButtonLink
              theme={ctaTheme}
              href={link}
              linkNewTab={linkNewTab}
              size={ctaSize}
            >
              {ctaText}
            </ButtonLink>
          )}
        </Styles.ActionWrapper>
      </InView>
    </Styles.ContentWrapper>
  );
});

export const PitchCarouselItem = React.memo(PitchCarouselItemNoMemo);
