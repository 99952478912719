import { ReactNode, useEffect, useRef } from 'react';

import { useInView } from 'framer-motion';

export interface IInViewProps {
  children: ReactNode;
  onChange?: (value: boolean) => void;
  once?: boolean;
}

export const InView = ({ children, onChange, once }: IInViewProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const value = useInView(ref, { once });
  useEffect(() => {
    onChange && onChange(value);
  }, [onChange, value]);
  return <div>{children}</div>;
};
