import styled, { css } from 'styled-components';

import { HideWhenConfig, ShowWhenConfig, Size } from './types';

import { em } from './css';

export const media = (size: number, features: 'min' | 'max' = 'min') =>
  ((first: any, ...args: any[]) => {
    if (features === 'min') {
      return css`
        @media screen and (min-width: ${em(`${size}px`)}) {
          ${css(first, ...args)}
        }
      `;
    }
    if (features === 'max') {
      return css`
        @media screen and (max-width: ${em(`${size}px`)}) {
          ${css(first, ...args)}
        }
      `;
    }
  }) as typeof css;

export const HideWhen = styled.div<HideWhenConfig>`
  ${(props) =>
    props.sizes.map((size: Size) => {
      const {
        theme: {
          grid: { breakpoints },
        },
      } = props;

      if (breakpoints[size]) {
        const feature = props?.feature || 'min';

        return css`
          ${media(breakpoints[size], feature)`
          display: none;
        `}
        `;
      }

      return null;
    })}
`;

export const ShowWhen = styled.div<ShowWhenConfig>`
  display: none;

  ${(props) =>
    props.sizes.map((size: Size) => {
      const {
        theme: {
          grid: { breakpoints },
        },
      } = props;

      if (breakpoints[size]) {
        const feature = props?.feature || 'min';

        return css`
          ${media(breakpoints[size], feature)`
          display: block;
        `}
        `;
      }

      return null;
    })}
`;
