import React from 'react';

import { Skeleton } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { SwiperSlide } from 'swiper/react';

import { useCarouselsQuery } from '../../graphql/carousels-query';
import { CarouselCard } from '../carousel-card/carousel-card';
import { Carousel, ICarouselProps } from './carousel/carousel';

export interface IInfiniteCarouselProps
  extends Omit<ICarouselProps, 'children' | 'isReady'> {
  carouselSlug: string;
}

export const InfiniteCarousel = observer(function InfiniteCarousel({
  carouselSlug,
  ...props
}: IInfiniteCarouselProps) {
  const { isLoading, data: carouselData } = useCarouselsQuery({
    variables: { slug: carouselSlug },
    context: { doNotBatch: true },
  });

  const banners = carouselData?.carousels.nodes?.[0]?.bannerContents;

  const cards =
    isLoading || !banners
      ? [1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
          <SwiperSlide key={`preload-${i}`} style={{ width: '430px' }}>
            <Skeleton w="410px" h="300px" mr="5" borderRadius="xl" />
          </SwiperSlide>
        ))
      : banners.map((carousel) => (
          <SwiperSlide
            key={carousel.id}
            style={{ width: '410px', marginRight: '20px' }}
          >
            <CarouselCard item={carousel} />
          </SwiperSlide>
        ));

  return <Carousel {...props}>{cards}</Carousel>;
});
