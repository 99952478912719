import { createIcon } from '@chakra-ui/icons';

export const TriangleIcon = createIcon({
  displayName: 'TriangleIcon',
  viewBox: '0 0 5 8',
  defaultProps: {
    fill: '#0049FF',
  },
  path: (
    <path
      id="Triangle"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52941 3.18274C5.15464 3.63461 5.15907 4.36403 4.52941 4.8191L1.13208 7.27442C0.50685 7.72628 0 7.47751 0 6.72289V1.27896C0 0.522488 0.50242 0.272357 1.13208 0.727425L4.52941 3.18274Z"
      fill="currentColor"
    />
  ),
});
