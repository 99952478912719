import {
  Box,
  BoxProps,
  Center,
  Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { ICarouselBannerContentsFragment } from '../../graphql/types';

export type ICarouselCardItem = Pick<
  ICarouselBannerContentsFragment,
  | 'id'
  | 'title'
  | 'overlayTheme'
  | 'link'
  | 'logo'
  | 'logoAltText'
  | 'description'
  | 'backgroundImageDesktop'
  | 'ctaText'
  | 'openLinkInNewTab'
>;

export interface ICarouselCardProps extends BoxProps {
  item: ICarouselCardItem;
}

export const CarouselCard = ({ item, ...props }: ICarouselCardProps) => {
  const {
    logo,
    backgroundImageDesktop,
    title,
    description,
    ctaText,
    link: linkURL,
    openLinkInNewTab: isExternal,
  } = item;

  const {
    onClose,
    onOpen,
    isOpen: isHovered,
  } = useDisclosure({ defaultIsOpen: false });
  return (
    <Box
      onMouseOver={onOpen}
      onMouseLeave={onClose}
      bgSize={isHovered ? '110%' : '100%'}
      w="410px"
      h="300px"
      pos="relative"
      overflow="hidden"
      borderRadius="2xl"
      {...props}
    >
      {backgroundImageDesktop && (
        <Image
          src={backgroundImageDesktop}
          objectFit="cover"
          position="absolute"
          outline="1px solid red"
          left="0"
          top="0"
          w="100%"
          h="100%"
          transition="transform 1s ease"
          transform={isHovered ? 'scale(1.08)' : 'scale(1)'}
          transformOrigin="center center"
        />
      )}
      <LinkBox
        as={Center}
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        top="0"
        zIndex={1}
        transition="opacity 0.5s ease"
        opacity={isHovered ? 1 : 0}
        bgColor={ctaText ? 'rgba(26, 26, 26, 0.7)' : 'transparent'}
      >
        <Heading size="republic-h4" color="white">
          {linkURL ? (
            <LinkOverlay href={linkURL} isExternal={Boolean(isExternal)}>
              {ctaText}
            </LinkOverlay>
          ) : (
            ctaText
          )}
        </Heading>
      </LinkBox>
      <Box
        // transition="transform 0.5s ease"
        // transform={isHovered ? 'translateY(100%)' : 'translateY(0)'}
        p="4"
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%);"
      >
        <HStack mb="2">
          {!!logo && !logo.includes('assets/fallback') && (
            <Image
              src={logo}
              boxSize="40px"
              borderRadius="lg"
              alt="Logo Image"
            />
          )}
          {!!title && (
            <Heading color="white" size="republic-h5" noOfLines={1}>
              {title}
            </Heading>
          )}
        </HStack>
        {!!description && (
          <Text color="white" noOfLines={2}>
            {description}
          </Text>
        )}
      </Box>
    </Box>
  );
};
