import { Fragment } from 'react';

import { Box, Heading, HStack, Image, Text } from '@chakra-ui/react';

import { IBusinessCase } from '../global-business-cases/cases';

export interface IGlobalBusinessCaseCardProps {
  businessCase: IBusinessCase;
}

export const GlobalBusinessCaseCard = ({
  businessCase,
}: IGlobalBusinessCaseCardProps) => {
  const {
    title,
    categories,
    facts,
    logoImage,
    backgroundImage,
    variant,
    withBorder,
  } = businessCase;
  return (
    <Box
      pos="relative"
      w="312px"
      h="436px"
      p="6"
      bg="red.300"
      borderRadius="2xl"
      color={variant === 'dark' ? 'white' : 'black'}
      backgroundImage={backgroundImage}
      backgroundSize="cover"
      cursor="pointer"
      border={withBorder ? '1px solid' : 'none'}
      borderColor="grey.200"
    >
      <Box
        as="p"
        textStyle="xs"
        color={variant === 'dark' ? 'grey.100' : 'grey.800'}
        mb="4"
      >
        {categories.map((category, idx) => (
          <Fragment key={category}>
            <Text as="span" mr="2">
              {category}
            </Text>
            {idx < categories.length - 1 && (
              <Text as="span" mr="2">
                &middot;
              </Text>
            )}
          </Fragment>
        ))}
      </Box>
      <Heading size="republic-h5" lineHeight="24px" mb="3">
        {title}
      </Heading>
      {facts && (
        <HStack>
          {facts.map((fact) => (
            <Box key={fact.description} minW="80px">
              <Text textStyle="sm" fontSize="18px" mb="0.5">
                {fact.value}
              </Text>
              <Text textStyle="sm">{fact.description}</Text>
            </Box>
          ))}
        </HStack>
      )}
      <Image
        pos="absolute"
        bottom="4"
        left="4"
        boxSize="49px"
        borderRadius="sm"
        src={logoImage}
      />
    </Box>
  );
};
