import { useRef } from 'react';

import { Text } from '@chakra-ui/react';

import { getWindow } from '@r-client/shared/util/core';

import { GlobalBusinessCases } from '../global-business-cases/global-business-cases';
import { GlobalCommunity } from '../global-community/global-community';
import { GlobalFocusCards } from '../global-focus-cards/global-focus-cards';
import { GlobalForFounders } from '../global-for-founders/global-for-founders';
import { GlobalForInvestors } from '../global-for-investors/global-for-investors';
import { GlobalHeader } from '../global-header/global-header';
import { GlobalInvestors } from '../global-investors/global-investors';
import { GlobalRegulationsBenefits } from '../global-regulations-benefits/global-regulations-benefits';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IGlobalPageProps {}

export const GlobalPage = (_props: IGlobalPageProps) => {
  const investorsRef = useRef<HTMLHeadingElement>(null);
  const foundersRef = useRef<HTMLHeadingElement>(null);

  const onClickRaiseCapital = () => {
    getWindow()?.scrollTo({
      top: foundersRef.current?.offsetTop,
      behavior: 'smooth',
    });
  };

  const onClickViewDeals = () => {
    getWindow()?.scrollTo({
      top: investorsRef.current?.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <GlobalHeader
        onClickRaiseCapital={onClickRaiseCapital}
        onClickViewDeals={onClickViewDeals}
      />
      <Text
        size="republic-body-sm"
        color="grey.500"
        textAlign="center"
        px="4"
        py="2"
      >
        The sole purpose of this page is to demonstrate the global presence of
        the Republic Group.
      </Text>
      <GlobalForInvestors headingRef={investorsRef} />
      <GlobalForFounders headingRef={foundersRef} />
      <GlobalFocusCards />
      <GlobalRegulationsBenefits />
      <GlobalInvestors />
      <GlobalBusinessCases />
      <GlobalCommunity
        onClickRaiseCapital={onClickRaiseCapital}
        onClickViewDeals={onClickViewDeals}
      />
    </>
  );
};
