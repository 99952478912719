import { Icon, IconProps } from '@chakra-ui/react';

export const CommunityEngagementPictogram = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      boxSize="12"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7004 4V20H25.3004V4H22.7004ZM7.30016 12.7L21.1566 20.7L19.8566 22.9517L6.00016 14.9517L7.30016 12.7ZM22.7004 44V28H25.3004V44H22.7004ZM19.7002 24.7L5.84375 32.7L7.14375 34.9517L21.0002 26.9517L19.7002 24.7ZM26.8438 20.7L40.7002 12.7L42.0002 14.9517L28.1437 22.9517L26.8438 20.7ZM28.3002 24.7L42.1566 32.7L40.8566 34.9517L27.0002 26.9517L28.3002 24.7Z"
        fill="currentColor"
      />
    </Icon>
  );
};
