import { ICarouselBanner } from '../types';

export const comparePriority = (a: ICarouselBanner, b: ICarouselBanner) => {
  const priorityA = a.priority ?? 0;
  const priorityB = b.priority ?? 0;

  let comparison = 0;
  if (priorityA > priorityB) {
    comparison = 1;
  } else if (priorityA < priorityB) {
    comparison = -1;
  }
  return comparison;
};
