// TODO: MK make sure lodash will work(it was fast-memoize before)
import memoize from 'lodash/memoize';
import { DefaultTheme } from 'styled-components';

import { common } from './common';
import { investor } from './investor';

export const colors = memoize(function colors<
  C extends keyof DefaultTheme['colors']
>(color?: C, tone?: keyof DefaultTheme['colors'][C]) {
  return ({ theme }: { theme: DefaultTheme }) =>
    theme.colors[color ?? 'primary'][tone ?? 'base'];
});

export { common, investor };
