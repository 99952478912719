import {
  IEuropeCardData,
  UnknownRecord,
} from '../../components/eu-offerings/eu-offerings-query';

export const cardDataFromSeedrsRecord = (
  record: UnknownRecord
): IEuropeCardData => {
  const investmentRaised = getValue<UnknownRecord>(
    record['investment_raised'],
    {}
  );
  const target = getValue<UnknownRecord>(record['investment_sought'], {});
  const valuation = getValue<UnknownRecord>(record['pre_money_valuation'], {});
  const address = getValue<UnknownRecord>(record['address'], {});
  const images = getImages(record);

  return {
    slug: getValue<string>(record['slug'], ''),
    coverImage: images.coverImage,
    logo: images.logo,
    title: getValue<string>(record['name'], ''),
    description: getValue<string>(record['description'], ''),
    currency: getValue<string>(record['currency'], ''),
    raisedAmountCents: getValue<number>(investmentRaised['amount_in_cents'], 0),
    targetAmountCents: getValue<number>(target['amount_in_cents'], 0),
    preMoneyValuationCents: getValue<number>(valuation['amount_in_cents'], 0),
    numberOfInvestors: getValue<number>(record['number_of_investors'], 0),
    percentageRaised: getValue<string>(record['percentage_amount_raised'], '0'),
    countryCode: getValue<string>(address['country'], ''),
    city: getValue<string>(address['city'], ''),
    type: getValue<string>(record['type'], ''),
    typeForDisplay: getTypeForDisplay(record['type']),
    status: getValue<string>(record['status'], ''),
  };
};

const getValue = <T>(value: unknown, fallback: T): T =>
  (typeof value === typeof fallback ? value : fallback) as T;

const getImages = (record: UnknownRecord) => {
  const coverImage = getValue<UnknownRecord>(record['cover_image'], {});
  const logo = getValue<UnknownRecord>(record['logo'], {});

  const result: Record<string, string> = {
    coverImage: getValue<string>(coverImage['cropped_location'], ''),
    logo: getValue<string>(logo['cropped_location'], ''),
  };

  Object.keys(result).forEach((key) => {
    result[key] = result[key].replace(/\\u0026/g, '&');
  });

  return result;
};

const getTypeForDisplay = (type: string | unknown) => {
  if (typeof type !== 'string') {
    return '';
  }

  const humanFriendlyType: Record<string, string> = {
    EquityCampaign: 'Equity',
    ConvertibleCampaign: 'Convertible',
  };

  return humanFriendlyType[type] || '';
};
