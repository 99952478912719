import cheekyPanda from '../assets/cheeky-panda.jpg';
import cheekyPandaLogo from '../assets/cheeky-panda-2x.png';
import cheekyPandaBg from '../assets/cheeky-panda-bg.png';
import cheekyPandaBgMd from '../assets/cheeky-panda-bg-md.png';
import { ReactComponent as RevolutLogo } from '../assets/revolut.svg';
import revolut from '../assets/revolut-2x.png';
import revolutBg from '../assets/revolut-bg.png';
import revolutBgMd from '../assets/revolut-bg-md.png';
import rheal from '../assets/rheal.png';
import rhealBg from '../assets/rheal-bg.png';
import rhealBgMd from '../assets/rheal-bg-md.png';
import rhealIcon from '../assets/rheal-logo-2x.png';
import { ISlide } from './types';

export const CONTENT_EU: ISlide[] = [
  {
    name: 'Revolut',
    bgImg: revolutBg,
    bgImgXs: revolutBgMd,
    bgImgMd: revolutBgMd,
    logoUrl: revolut,
    logoUrl2x: revolut,
    logoXsUrl: { type: 'svg', icon: RevolutLogo },
    bgColor: 'black',
    bgSize: '330px 262px',
    bgXsSize: '37%',
    bgMdSize: '50% 100%',
    bgPosition: 'top center',
    bgXsPosition: 'center right',
    bgMdPosition: 'bottom right',
    textColor: 'white',
    linearGradient: 'linear(to-b, rgba(0, 0, 0, 0) 0%, #000000 62.16%)',
    description: 'Funded Aug 2017',
    title: '5000%+ valuation change since their raise',
    subTitle:
      'Revolut is the digital banking alternative designed for your global lifestyle.',
    subTitleWidth: 317,
    feats: [
      {
        title: 'Investors',
        value: '4,260',
      },
      {
        title: 'Raised',
        value: '£3.9M+',
      },
      {
        title: 'Rounds raised',
        value: '1',
      },
    ],
    buttonBdColor: 'rgba(225, 225, 225, 0.3)',
    buttonBgColor: 'transparent',
    href: 'https://europe.republic.com/businesses/revolut/sections/about',
    buttonText: 'View business',
  },
  {
    name: 'Rheal',
    bgImg: rhealBg,
    bgImgXs: rhealBgMd,
    bgImgMd: rhealBgMd,
    logoUrl: rheal,
    logoUrl2x: rheal,
    logoXsUrl: { type: 'image', src: rhealIcon },
    bgColor: 'white',
    bgSize: '330px 262px',
    bgXsSize: 'cover',
    bgMdSize: 'cover',
    bgPosition: 'top center',
    bgXsPosition: 'center',
    bgMdPosition: 'center',
    textColor: 'black',
    linearGradient:
      'linear(to-b, rgba(254, 255, 250, 0) 33.04%, #FEFFFA 49.04%)',
    description: 'Funded May 2021',
    title: '367%+ valuation change since their rase',
    subTitle:
      'High-growth health food brand, providing a natural, innovative & convenient way of consuming Superfoods!',
    subTitleWidth: 317,
    feats: [
      {
        title: 'Investors',
        value: '396',
      },
      {
        title: 'Raised',
        value: '$5.9M',
      },
      {
        title: 'Rounds raised',
        value: '2',
      },
    ],
    buttonBdColor: 'rgba(0, 0, 0, 0.1)',
    buttonBgColor: 'white',
    buttonText: 'View campaign',
    href: 'https://europe.republic.com/businesses/rheal/sections/about',
  },
  {
    name: 'The Cheeky Panda',
    bgImg: cheekyPandaBg,
    bgImgXs: cheekyPandaBgMd,
    bgImgMd: cheekyPandaBgMd,
    logoUrl: cheekyPanda,
    logoUrl2x: cheekyPanda,
    logoXsUrl: { type: 'image', src: cheekyPandaLogo },
    bgColor: 'white',
    bgSize: '330px 262px',
    bgXsSize: '57%',
    bgMdSize: '45% 100%',
    bgPosition: 'top center',
    bgXsPosition: '130% -20px',
    bgMdPosition: '100% -40px',
    textColor: 'black',
    linearGradient:
      'linear(to-b, rgba(254, 255, 250, 0) 33.04%, #FEFFFF 49.04%)',
    description: 'Funded Dec 2022',
    title: '£13M raised from 5k+ investors',
    subTitle:
      'The Cheeky Panda is a B Corp which offers a range of sustainable bamboo tissue and hygiene products',
    subTitleWidth: 317,
    feats: [
      {
        value: '5,182',
        title: 'Investors',
      },
      {
        value: '$13M',
        title: 'Raised',
      },
      {
        value: '8',
        title: 'Rounds raised',
      },
    ],
    buttonBdColor: 'rgba(0, 0, 0, 0.1)',
    buttonBgColor: 'white',
    buttonText: 'View business',
    href: 'https://europe.republic.com/businesses/the-cheeky-panda/sections/about',
  },
];
