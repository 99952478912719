import { Icon, IconProps } from '@chakra-ui/react';

export const RwaTokenizationPictogram = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      boxSize="12"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5572 8L18.042 13.5155L23.5575 19.0308L29.0728 13.5153L23.5572 8ZM33.773 18.1L28.2578 23.6155L33.7733 29.1308L39.2886 23.6153L33.773 18.1ZM8 23.5731L13.5155 18.0577L19.0309 23.5732L13.5154 29.0886L8 23.5731ZM23.5155 28.3577L18 33.8731L23.5154 39.3885L29.0309 33.8731L23.5155 28.3577Z"
        fill="currentColor"
      />
    </Icon>
  );
};
