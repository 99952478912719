import 'swiper/css';
import 'swiper/css/autoplay';

import { useEffect, useRef, useState } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { Autoplay } from 'swiper';
import { Swiper } from 'swiper/react';
import { type Swiper as ISwiper } from 'swiper/types';

import styles from './carousel.module.scss';

export interface ICarouselProps extends BoxProps {
  children?: React.ReactNode;
}

export const Carousel = ({ children, ...props }: ICarouselProps) => {
  const swiperRef = useRef<ISwiper | null>(null);

  const [duration, setDuration] = useState<number>(0);
  const [isStarted, setStarted] = useState(false);
  const [isAnimated, setAnimated] = useState(false);

  const startTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [isPlaying, setIsPlaying] = useState(true);

  // stop
  const stopAutoplay = () => {
    if (!isPlaying) return;
    const swiper = swiperRef.current;
    if (!swiper) return;
    if (startTimerRef.current) clearTimeout(startTimerRef.current);

    // Stop slide at current translate.
    swiper.setTranslate(swiper.getTranslate());

    // currentSlideWidth for slidesPerView > 1
    const currentSlide = swiper.slides[swiper.activeIndex];
    const currentSlideWidth = (currentSlide as HTMLElement)?.offsetWidth;

    const distanceRatio = Math.abs(
      (currentSlideWidth * swiper.activeIndex + swiper.getTranslate()) /
        currentSlideWidth
    );

    // The duration that playing to the next slide
    setDuration((swiper?.params?.speed ?? 0) * distanceRatio);
    swiper.autoplay.stop();
    setIsPlaying(false);
  };

  const startAutoplay = ({ delay = duration, force = false } = {}) => {
    if (isPlaying && !force) {
      return;
    }
    const swiper = swiperRef.current;
    if (!swiper || swiper.destroyed) {
      return;
    }

    const distance = swiper.width * swiper.activeIndex + swiper.getTranslate();

    // Avoid distance that is exactly 0
    const durationTemp = distance !== 0 ? duration : 0;
    setDuration(durationTemp);
    swiper.slideTo(swiper.activeIndex, durationTemp);

    startTimerRef.current = setTimeout(() => {
      swiper.autoplay.start();
    }, delay);

    setIsPlaying(true);
  };

  useEffect(() => {
    setAnimated(swiperRef.current?.animating ?? false);
  }, [swiperRef.current?.animating, setAnimated]);

  useEffect(() => {
    if (isStarted && !isAnimated && swiperRef.current) {
      swiperRef.current.animating = false;
      stopAutoplay();

      setTimeout(() => {
        startAutoplay({ force: true });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStarted, isAnimated]);

  return (
    <Box
      className={styles.wrapper}
      onMouseLeave={() => startAutoplay()}
      onMouseEnter={() => stopAutoplay()}
      {...props}
    >
      <Swiper
        className={styles.swiper}
        modules={[Autoplay]}
        speed={10000}
        spaceBetween={20}
        slidesPerView="auto"
        allowTouchMove={false}
        longSwipesRatio={0.1}
        loop
        loopedSlides={10}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        onSwiper={(swiper: ISwiper) => {
          setStarted(true);
          swiperRef.current = swiper;
        }}
      >
        {children}
      </Swiper>
    </Box>
  );
};
