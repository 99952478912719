import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import {
  IOfferingBaseScopeEnum,
  IOfferingScopeEnum,
} from '@r-client/data/graphql-types';

import { BuildPortfolio } from '../build-portfolio/build-portfolio';
import { CoInvest } from '../co-invest/co-invest';
import { Deals } from '../deals/deals';
import { ForFounders } from '../for-founders/for-founders';
import { PortfolioWins } from '../portfolio-wins/portfolio-wins';
import { Stats } from '../stats/stats';
import { UsOfferings } from '../us-offerings/us-offerings';

export const UsPage = observer(function UsPage() {
  return (
    <>
      <Container maxW="1152px">
        <Box mb="125px" pt="10">
          <Stats region="us" />
        </Box>

        <Box mb="100px">
          <Heading size="republic-h3" fontWeight="semibold" mb="2">
            Most traction
          </Heading>
          <Text textStyle="lg" color="grey.600" mb="4">
            The deals attracting the most interest right now
          </Text>
          <UsOfferings
            first={3}
            scopeFilter={{
              scopeName: IOfferingScopeEnum.MostTraction,
              mostTractionPeriodDays: 7,
            }}
            scopeBaseFilter={[
              IOfferingBaseScopeEnum.Published,
              IOfferingBaseScopeEnum.ShowOnHomepage,
            ]}
            viewAllButtonHref="/companies?sorting=most-traction-last-week"
          />
        </Box>

        <Box mb="142px">
          <Heading size="republic-h3" fontWeight="semibold" mb="2">
            Just launched
          </Heading>
          <Text textStyle="md" color="grey.600" mb="4">
            The deals that have recently opened for investment
          </Text>
          <UsOfferings
            first={3}
            scopeFilter={{
              scopeName: IOfferingScopeEnum.NewDeals,
              newDealsPeriodDays: 120,
            }}
            scopeBaseFilter={[
              IOfferingBaseScopeEnum.Published,
              IOfferingBaseScopeEnum.ShowOnHomepage,
            ]}
            viewAllButtonHref="/companies?sorting=recently-launched"
          />
        </Box>

        <BuildPortfolio region="us" />

        <Deals region="us" />
      </Container>

      <Box bg="grey.50">
        <PortfolioWins region="us" />
        <CoInvest />
      </Box>

      <Container maxW="1152px">
        <ForFounders variant="us" />
      </Container>
    </>
  );
});
