import afcWimbledonBackground from './cases-assets/afc-wimbledon-bg.png';
import afcWimbledonLogo from './cases-assets/afc-wimbledon-logo.png';
import avalancheBackground from './cases-assets/avalanche-bg.png';
import avalancheLogo from './cases-assets/avalanche-logo.png';
import beaconsBackground from './cases-assets/beacons-bg.png';
import beaconsLogo from './cases-assets/beacons-logo.png';
import cartaBackground from './cases-assets/carta-bg.png';
import cartaLogo from './cases-assets/carta-logo.png';
import feminvestBackground from './cases-assets/feminvest-bg.png';
import feminvestLogo from './cases-assets/feminvest-logo.png';
import fireflyBackground from './cases-assets/firefly-bg.png';
import fireflyLogo from './cases-assets/firefly-logo.png';
import gumroadBackground from './cases-assets/gumroad-bg.png';
import gumroadLogo from './cases-assets/gumroad-logo.png';
import mavenBackground from './cases-assets/maven-bg.png';
import mavenLogo from './cases-assets/maven-logo.png';
import revolutBackground from './cases-assets/revolut-bg.png';
import revolutLogo from './cases-assets/revolut-logo.png';
import starAtlasBackground from './cases-assets/star-atlas-bg.png';
import starAtlasLogo from './cases-assets/star-atlas-logo.png';
import truthArtsBackground from './cases-assets/truth-arts-bg.png';
import truthArtsLogo from './cases-assets/truth-arts-logo.png';

export interface IFact {
  value: string;
  description: string;
}

export interface IBusinessCase {
  id: string;
  categories: string[];
  variant: 'dark' | 'light';
  title: string;
  backgroundImage: string;
  logoImage: string;
  facts?: IFact[];
  withBorder?: boolean;
}

export const cases: IBusinessCase[] = [
  {
    id: 'gumroad',
    categories: ['Community funding', 'Reg CF'],
    variant: 'light',
    title:
      'Gumroad was the first business to reach $5M raised under the SEC’s new rule limit',
    facts: [
      {
        value: '7,331',
        description: 'Investors',
      },
      {
        value: '$5M',
        description: 'Raised',
      },
    ],
    logoImage: gumroadLogo,
    backgroundImage: gumroadBackground,
  },
  {
    id: 'revolut',
    categories: ['Community funding'],
    variant: 'dark',
    title: 'Revolut propelled Republic  investors to over 5,000% growth',
    facts: [
      {
        value: '3,516',
        description: 'Investors',
      },
      {
        value: '£3.8M',
        description: 'Raised',
      },
    ],
    logoImage: revolutLogo,
    backgroundImage: revolutBackground,
  },
  {
    id: 'carta',
    categories: ['Series F', 'Series G'],
    variant: 'light',
    title:
      'Carta partnered with Republic to provide superior cap table management',

    logoImage: cartaLogo,
    backgroundImage: cartaBackground,
    withBorder: true,
  },
  {
    id: 'avalanche',
    categories: ['Web3 Advisory', 'Token Design'],
    title:
      'Avalanche’s rapid ascent culminated in a $42 million public token offering',
    variant: 'dark',
    logoImage: avalancheLogo,
    backgroundImage: avalancheBackground,
  },
  {
    id: 'firefly',
    categories: ['Series A', 'Series B', 'Series G'],
    title:
      'Firefly was the first private ground-based system to launch a rocket into orbit',
    variant: 'dark',
    logoImage: fireflyLogo,
    backgroundImage: fireflyBackground,
  },
  {
    id: 'starAtlas',
    categories: ['Web3 advisory', 'Token Design'],
    title:
      'Star Atlas architected a galactic metaverse monetary policy and tokenomics ',
    variant: 'dark',
    logoImage: starAtlasLogo,
    backgroundImage: starAtlasBackground,
  },
  {
    id: 'beacons',
    categories: ['Community funding', 'Reg D'],
    title: 'Beacons helps creators monetize with AI tools',
    facts: [
      {
        value: '185',
        description: 'Investors',
      },
      {
        value: '$200k',
        description: 'Raised',
      },
    ],
    variant: 'light',
    logoImage: beaconsLogo,
    backgroundImage: beaconsBackground,
  },
  {
    id: 'maven',
    categories: ['Community funding', 'Reg CF'],
    title:
      'Maven raises $20M Series A led by a16z after $750K Reg CF campaign on Republic',
    variant: 'dark',
    logoImage: mavenLogo,
    backgroundImage: mavenBackground,
  },
  {
    id: 'truthArts',
    categories: ['Sharedops™'],
    title:
      'Truth Arts revolutionizes NFTs by distributing $5M in free equity to holders',
    variant: 'dark',
    logoImage: truthArtsLogo,
    backgroundImage: truthArtsBackground,
  },
  {
    id: 'afcWimbledon',
    categories: ['Community funding', 'Reg D'],
    title: 'AFC Wimbledon raised £2.4M on Republic to build a new stadium',
    variant: 'dark',
    facts: [
      {
        value: '5,070',
        description: 'Investors',
      },
      {
        value: '£3.9M+',
        description: 'Raised',
      },
    ],
    logoImage: afcWimbledonLogo,
    backgroundImage: afcWimbledonBackground,
  },
  {
    id: 'feminvest',
    categories: ['Community funding'],
    title:
      'Feminvest engaged the over 1,500+ female investors to raised $1M under 4 minutes',
    variant: 'light',
    facts: [
      {
        value: '87.5%',
        description: 'Female investors',
      },
      {
        value: '$1M',
        description: 'Raised',
      },
    ],
    logoImage: feminvestLogo,
    backgroundImage: feminvestBackground,
  },
];
