/* eslint-disable no-debugger */
import styled, { css } from 'styled-components';

import { media } from '../../../utils/media';
import { ColProps, GridProps, RowProps } from './types';

import { padding, rem } from '../../../utils/css';

// Corrects the return type of Object.keys
function getKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

export const Grid = styled.div<GridProps>`
  margin-right: auto;
  margin-left: auto;

  ${(p) => {
    // debugger;

    return (
      (p.fluid || p.padded) &&
      css`
        ${padding(
          0,
          rem(`${p.theme.grid.outerMargin}px`),
          0,
          rem(`${p.theme.grid.outerMargin}px`)
        )}
      `
    );
  }}

  ${(p) => {
    return (
      !p.fluid &&
      css`
        ${getKeys(p.theme.grid.breakpoints).map(
          (bp) => css`
            ${media(p.theme.grid.breakpoints[bp])`
            max-width: ${rem(`${p.theme.grid.container[bp]}px`)};
          `}
          `
        )}
      `
    );
  }}
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  ${(p) => {
    // debugger;
    return getKeys(p.theme.grid.breakpoints).reduce((memo, bp) => {
      const breakpointProp = p[bp];
      if (breakpointProp) {
        return css<RowProps>`
          ${memo}
          ${media(p.theme.grid.breakpoints[bp])`
            ${() => {
              switch (true) {
                case breakpointProp.justifySpaceBetween:
                  return 'justify-content: space-between;';
                case breakpointProp.justifySpaceAround:
                  return 'justify-content: space-around;';
                case breakpointProp.justifyFlexStart:
                  return 'justify-content: flex-start;';
                case breakpointProp.justifyFlexEnd:
                  return 'justify-content: flex-end;';
                case breakpointProp.justifyEnd:
                  return 'justify-content: end;';
                case breakpointProp.justifyStart:
                  return 'justify-content: start;';
                case breakpointProp.justifyLeft:
                  return 'justify-content: left;';
                case breakpointProp.justifyRight:
                  return 'justify-content: right;';
                case breakpointProp.justifyCenter:
                  return 'justify-content: center;';
              }
            }}
            ${() => {
              switch (true) {
                case breakpointProp.alignBaseline:
                  return 'align-items: baseline;';
                case breakpointProp.alignCenter:
                  return 'align-items: center;';
                case breakpointProp.alignStart:
                  return 'align-items: start;';
                case breakpointProp.alignEnd:
                  return 'align-items: end;';
                case breakpointProp.alignflexStart:
                  return 'align-items: flex-start;';
                case breakpointProp.alignFlexEnd:
                  return 'align-items: flex-end;';
                case breakpointProp.alignLeft:
                  return 'align-items: left;';
                case breakpointProp.alignRight:
                  return 'align-items: right';
                case breakpointProp.alignStretch:
                  return 'align-items: stretch';
              }
            }}
        `}
        `;
      } else {
        return memo;
      }
    }, css<RowProps>``);
  }}
`;

export const Col = styled.div<ColProps>`
  box-sizing: border-box;
  flex: 0 0 auto;
  ${(p) =>
    padding(
      0,
      rem(`${p.theme.grid.gutter}px`),
      0,
      rem(`${p.theme.grid.gutter}px`)
    )}
  ${(p) =>
    getKeys(p.theme.grid.breakpoints).map((bp) => {
      const breakpointColumns = p[bp];
      if (breakpointColumns !== undefined) {
        if (breakpointColumns === false) {
          return css`
            display: none;
          `;
        } else {
          return css`
            ${media(p.theme.grid.breakpoints[bp])`
              flex-basis: ${
                (100 / p.theme.grid.totalCols) * breakpointColumns
              }%;
              max-width: ${(100 / p.theme.grid.totalCols) * breakpointColumns}%;
              display: block;
            `}
          `;
        }
      }
    })}
`;
