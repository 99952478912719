import { Box, Center, Heading, SimpleGrid } from '@chakra-ui/react';

import { AngelList } from './icons/angel-list';
import { Binance } from './icons/binance';
import { BrewanHovard } from './icons/brewan-hovard';
import { BroadHeaven } from './icons/broadheavent';
import { FoundersFund } from './icons/founders-fund';
import { Galaxy } from './icons/galaxy';
import { Hof } from './icons/hof';
import { MorganStanley } from './icons/morgan-stanley';
import { MotleyFool } from './icons/motley-fool';
import { Nea } from './icons/nea';
import { OysterVentures } from './icons/oyster-ventures';
import { Valor } from './icons/valor';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IGlobalInvestorsProps {}

export const GlobalInvestors = (_props: IGlobalInvestorsProps) => {
  return (
    <Box bg="grey.50" py="20" px="4">
      <Box maxWidth="1152px" mx="auto">
        <Heading size="republic-h4" mx="4" mb="2">
          Republic investors
        </Heading>
      </Box>

      <SimpleGrid columns={[3, 3, 4, 4, 6]} maxWidth="1176px" mx="auto">
        <Center h={['100px', '125px']}>
          <Galaxy w={['80px', '107px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <Binance w={['80px', '121px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <AngelList w={['80px', '121px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <Valor w={['69px', '121px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <BrewanHovard w={['106px', '167px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <Hof w={['60px', '167px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <OysterVentures w={['80px', '105px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <Nea w={['59px', '72px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <MorganStanley w={['95px', '142px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <BroadHeaven w={['84px', '129px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <FoundersFund w={['100px', '159px']} />
        </Center>
        <Center h={['100px', '125px']}>
          <MotleyFool w={['83px', '128px']} />
        </Center>
      </SimpleGrid>
    </Box>
  );
};
