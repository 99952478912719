import React from 'react';

import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

export function useLazyImage(src: string): [string, React.Dispatch<string>] {
  const [loadedSrc, setLoadedSrc] = React.useState<string>('');

  useIsomorphicLayoutEffect(() => {
    const imageLoader = new Image();
    imageLoader.onload = () => setLoadedSrc(src);
    imageLoader.src = src;
  }, [src]);

  const setNewSrc = (newSrc: string) => setLoadedSrc(newSrc);

  return [loadedSrc, setNewSrc];
}
