import { RaiseCapitalPictogram } from '@r-client/dls/ui/pictograms';

import { CardButton } from '../../card-button/card-button';
import { Section } from '../section';

export const RAISE_CAPITAL = (
  <Section icon={RaiseCapitalPictogram} title="Raise capital">
    <CardButton
      title="Early stage"
      description="Funding for growth"
      href="/raise"
    />
    <CardButton
      title="Community raise"
      description="Activate your community’s support"
      href="/raise"
    />
  </Section>
);
