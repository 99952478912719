import { Button } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import Link from 'next/link';

import { CTASize } from '../types';

export interface IButtonLinkProps {
  theme?: Maybe<string>;
  size?: CTASize;
  linkNewTab?: Maybe<boolean>;
  children: React.ReactNode;
  href: string;
}

export const ButtonLink = ({
  theme = 'primary',
  size,
  children,
  href,
  linkNewTab,
}: IButtonLinkProps) => {
  const styles =
    theme === 'primary'
      ? { variant: 'republic-primary' as const }
      : theme === 'purple'
      ? { bg: 'purple.900', color: 'white', boxShadow: 'none' }
      : theme === 'white'
      ? { bg: 'white', color: 'black' }
      : {};

  return (
    <Button {...styles} size={size}>
      <Link href={href} target={linkNewTab ? '_blank' : '_self'}>
        {children}
      </Link>
    </Button>
  );
};
