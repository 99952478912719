import { IStatBoxProps } from '../../components/stat-box/stat-box';

export const REPUBLIC_STATS: IStatBoxProps[] = [
  {
    title: '3M+',
    description: 'Global investor community',
  },
  {
    title: '2,500+',
    description: 'Ventures supported',
  },
  {
    title: '31',
    description: 'Unicorns in portfolio',
  },
  {
    title: '$2.6B+',
    description: 'Capital raised',
  },
];

export const GLOBAL_STATS: IStatBoxProps[] = [
  {
    title: '3M+',
    description: 'Investor community',
  },
  {
    title: '2,500+',
    description: 'Ventures supported',
  },
  {
    title: '31',
    description: 'Unicorns in portfolio',
  },
  {
    title: '$2.6B+',
    description: 'Capital deployed',
  },
  {
    title: '150+',
    description: 'Countries',
  },
  {
    title: '$1.5B+',
    description: 'Delegated assets',
  },
];
