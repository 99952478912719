import { IOffering, ITeaserPage } from '@r-client/data/graphql-types';

export const getSectorTagsWithUniqueParents = (offering: IOffering) => {
  return (
    offering.tags?.filter((tag, index, tagsArr) => {
      const sectorTag = tag.topLevelSectorTag;
      let hasUniqueSectorParent = false;
      if (sectorTag) {
        hasUniqueSectorParent =
          tagsArr.findIndex((t) => {
            return t.topLevelSectorTag?.displayName === sectorTag.displayName;
          }) === index;
      }
      return hasUniqueSectorParent;
    }) ?? []
  );
};

export const isTeaserPage = (
  offering: IOffering | ITeaserPage
): offering is ITeaserPage => {
  return offering.__typename === 'TeaserPage';
};

export const getTags = (offering: IOffering | ITeaserPage) => {
  if (isTeaserPage(offering)) {
    return offering.tag ? [offering.tag] : [];
  }

  const sectorTagsWithUniqueParents = getSectorTagsWithUniqueParents(offering);
  const impactTags = offering.tags?.filter((tag) => tag?.impact) ?? [];
  const tags =
    sectorTagsWithUniqueParents.concat(impactTags).map((tag) => {
      const sectorTag = tag?.topLevelSectorTag;
      const ret = {
        displayName: sectorTag
          ? sectorTag?.displayName
          : tag.displayName || tag?.id,
        colorHex: sectorTag ? '#eee' : tag.colorHex || '',
      };
      return ret;
    }) ?? [];
  if (offering.crypto) {
    tags.unshift({
      displayName: 'crypto',
      colorHex: '#161d2d',
    });
  }

  return tags.slice(0, 3);
};
