import { Icon, IconProps } from '@chakra-ui/react';

export const OnchainInfraPictogram = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      boxSize="12"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7002 6H6.7002V8.6H19.7002V6ZM19.7002 12.7001H6.7002V15.3001H19.7002V12.7001ZM6.7002 19.4L19.7002 19.4V22H6.7002V19.4ZM19.7002 26.1H6.7002V28.7H19.7002V26.1ZM6.7002 32.8H19.7002V35.4H6.7002V32.8ZM19.7002 39.5H6.7002V42.1H19.7002V39.5ZM28.2998 6H41.2998V8.6H28.2998V6ZM41.2998 12.7001H28.2998V15.3001H41.2998V12.7001ZM28.2998 19.4L41.2998 19.4V22H28.2998V19.4ZM41.2998 26.1H28.2998V28.7H41.2998V26.1ZM28.2998 32.8H41.2998V35.4H28.2998V32.8ZM41.2998 39.5H28.2998V42.1H41.2998V39.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
