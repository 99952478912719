import { OnchainInfraPictogram } from '@r-client/dls/ui/pictograms';

import { CardButton } from '../../card-button/card-button';
import { Section } from '../section';

export const ONCHAIN_INFRASTRUCTURE = (
  <Section icon={OnchainInfraPictogram} title="On-chain infrastructure">
    <CardButton
      title="Infrastructure"
      description="Test- and main-net support, validator tooling, RPC infrastructure"
      href="/infrastructure"
    />
    <CardButton
      title="Staking"
      description="PoS staking, white label validators, on demand staking integrations"
      href="/infrastructure"
    />
    <CardButton
      title="Wallet-as-a-service"
      description="Access multiple blockchains on your platform"
      href="/infrastructure"
    />
    <CardButton
      title="Foundation support"
      description="Network tooling, validator monitoring and alerting"
      href="/infrastructure"
    />
  </Section>
);
