import { Icon, IconProps } from '@chakra-ui/react';

export const RaiseCapitalPictogram = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      boxSize="12"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7003 4V20H25.3003V4H22.7003ZM44 22.7H28V25.3L44 25.3V22.7ZM22.7003 44V28H25.3003V44H22.7003ZM20 22.7H4V25.3L20 25.3V22.7Z"
        fill="currentColor"
      />
    </Icon>
  );
};
