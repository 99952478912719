import React from 'react';

import { Wrapper } from './carousel-item-wrapper';

interface Props {
  backgroundColor?: string;
  height?: string;
  desktopHeight?: string;
}

export const CarouselItemWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  backgroundColor,
  height,
  desktopHeight,
}) => (
  <Wrapper
    desktopHeight={desktopHeight}
    height={height}
    backgroundColor={backgroundColor}
  >
    {children}
  </Wrapper>
);
