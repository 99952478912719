import { createIcon } from '@chakra-ui/icons';

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'currentColor',
  },
  path: (
    <path
      d="M4 12a1 1 0 0 1 .293-.707l6-6a1 1 0 1 1 1.414 1.414L7.414 11H19a1 1 0 1 1 0 2H7.414l4.293 4.293a1 1 0 0 1-1.414 1.414l-6-6A1 1 0 0 1 4 12"
      fill="currentColor"
    />
  ),
});
