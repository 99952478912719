import { Icon, IconProps } from '@chakra-ui/react';

export const BrewanHovard = (props: IconProps) => {
  return (
    <Icon width="169" height="12" viewBox="0 0 169 12" fill="none" {...props}>
      <path
        d="M0.892944 10.612L1.6486 10.5129C2.45462 10.389 2.55538 10.1661 2.55538 9.57151V2.46146C2.55538 1.84212 2.47981 1.61915 1.6486 1.52006L0.892944 1.42096V1.198H5.35128C7.21525 1.198 9.07914 1.71825 9.07914 3.42764C9.07914 4.79019 7.84493 5.50863 6.15731 5.7316V5.78114C8.17237 5.90501 9.53256 6.5739 9.53256 8.13463C9.53256 9.91836 7.94569 10.835 5.50241 10.835H0.892944V10.612ZM5.17496 5.65728C6.76183 5.65728 7.36637 4.91406 7.36637 3.42764C7.36637 1.81734 6.66108 1.44574 5.20015 1.44574H4.14224V5.65728H5.17496ZM4.14224 9.74494C4.14224 10.3395 4.39412 10.5872 5.04902 10.5872H5.45204C6.96334 10.5872 7.69381 9.8936 7.69381 8.13463C7.69381 6.49958 6.76183 5.90501 5.14978 5.90501H4.14224V9.74494Z"
        fill="#4D4D4D"
      />
      <path
        d="M22.1012 10.8598C21.1945 10.8598 21.0937 10.6616 20.7663 10.0423L18.8016 6.27662C18.6756 6.30139 18.5749 6.30139 18.4741 6.30139C18.0711 6.30139 17.6933 6.27662 17.391 6.27662V9.59628C17.391 10.2156 17.4666 10.4386 18.2978 10.5377L19.0535 10.6368V10.8598C18.1971 10.8598 17.4666 10.835 16.6102 10.835C15.7538 10.835 15.0233 10.8598 14.1417 10.8598V10.6368L14.8974 10.5377C15.7034 10.4138 15.8041 10.1909 15.8041 9.59628V2.46146C15.8041 1.84212 15.7286 1.61915 14.8974 1.52006L14.1417 1.42096V1.198H18.3985C20.9678 1.198 22.605 1.89166 22.605 3.72492C22.605 5.11225 21.3708 5.80592 20.2121 6.02888C20.3885 6.25185 20.6151 6.54913 20.867 6.99506L22.1768 9.32379C22.6302 10.1413 22.8065 10.389 23.4614 10.5377L23.9904 10.6616V10.8846H22.1012V10.8598ZM17.391 6.00411C17.7941 6.02888 18.1215 6.02888 18.4993 6.02888C20.0862 6.02888 20.8922 5.38477 20.8922 3.74969C20.8922 1.96599 20.061 1.42096 18.3985 1.42096H17.391V6.00411Z"
        fill="#4D4D4D"
      />
      <path
        d="M32.0009 5.7316C32.9328 5.7316 33.2351 5.11225 33.7137 3.60105H33.9656C33.9403 4.34426 33.9403 5.11225 33.9403 5.85547C33.9403 6.59868 33.9403 7.36669 33.9656 8.10986H33.7137C33.1847 6.49958 32.9328 5.95456 32.0009 5.95456H30.9429V9.72017C30.9429 10.3147 31.1948 10.5625 31.8497 10.5625H32.7817C34.4693 10.5625 34.8723 9.94313 35.8295 7.44099H36.1317L35.9806 10.8102H27.7189V10.5872L28.4745 10.4882C29.2805 10.3643 29.3813 10.1413 29.3813 9.54674V2.46146C29.3813 1.84212 29.3057 1.61915 28.4745 1.52006L27.7189 1.42096V1.198H35.5524L35.7035 4.29472H35.4013C34.5449 2.11463 34.1167 1.47051 32.7061 1.47051H30.9682V5.75637H32.0009V5.7316Z"
        fill="#4D4D4D"
      />
      <path
        d="M41.5971 2.95694C41.1941 1.86689 40.8918 1.5696 40.2621 1.49528L39.7332 1.42096V1.198C40.5644 1.22277 41.3956 1.22277 42.2268 1.22277C43.058 1.22277 43.8641 1.22277 44.7204 1.198V1.42096L43.8892 1.52006C43.1084 1.61915 43.0329 2.01553 43.2595 2.63488L45.6776 9.22472L47.7934 3.55151C48.3224 2.16417 48.1964 1.69347 47.2141 1.52006L46.4836 1.42096V1.198C47.1386 1.22277 47.7682 1.22277 48.398 1.22277C49.0529 1.22277 49.6825 1.22277 50.3374 1.198V1.42096L49.7833 1.5696C48.9521 1.74302 48.6499 2.18895 48.0453 3.70015L45.9043 9.27425L45.325 10.9093H44.6449L41.5971 2.95694Z"
        fill="#4D4D4D"
      />
      <path
        d="M59.1534 10.8356C58.3473 10.8356 57.6421 10.8604 56.836 10.8604V10.6374L57.6672 10.5383C58.4481 10.4392 58.4229 10.0428 58.171 9.42347L57.5161 7.73887H53.7127L53.4104 8.50687C52.8563 9.91899 52.8563 10.3401 53.8386 10.5135L54.569 10.6374V10.8604C53.9394 10.8604 53.4608 10.8356 52.8311 10.8356C52.2014 10.8356 51.622 10.8604 50.9923 10.8604V10.6374L51.4709 10.5135C52.2265 10.3153 52.5288 9.91899 53.1081 8.38298L55.3247 2.75934L55.904 1.12427H56.6597L59.8082 9.10145C60.2365 10.1915 60.4128 10.4887 61.0173 10.5631L61.521 10.6374V10.8604C60.6647 10.8604 59.9846 10.8356 59.1534 10.8356ZM53.8134 7.41678H57.3902L55.6018 2.78411L53.8134 7.41678Z"
        fill="#4D4D4D"
      />
      <path
        d="M66.4573 2.36237V8.80357C66.4573 10.0423 66.7093 10.389 67.4901 10.5129L68.0945 10.612V10.835C67.4397 10.835 66.9362 10.8102 66.2814 10.8102C65.626 10.8102 65.0973 10.835 64.4678 10.835V10.612L65.0721 10.5129C65.8781 10.389 66.1302 10.0174 66.1302 8.80357V2.46146C66.1302 1.84212 66.0546 1.61915 65.2234 1.52006L64.4678 1.42096V1.198H67.6162L72.8807 8.82834V3.22945C72.8807 1.99076 72.6286 1.64393 71.8226 1.52006L71.2183 1.42096V1.198C71.873 1.198 72.4018 1.22277 73.032 1.22277C73.6867 1.22277 74.1902 1.198 74.845 1.198V1.42096L74.2406 1.52006C73.4598 1.64393 73.2078 1.99076 73.2078 3.22945V10.8846H72.3009L66.4573 2.36237Z"
        fill="#4D4D4D"
      />
      <path
        d="M96.6083 2.46146C96.6083 1.84212 96.5327 1.61915 95.7015 1.52006L94.9459 1.42096V1.198C95.8023 1.198 96.5327 1.22277 97.4143 1.22277C98.2708 1.22277 99.0011 1.198 99.8576 1.198V1.42096L99.102 1.52006C98.296 1.64393 98.1951 1.86689 98.1951 2.46146V9.57151C98.1951 10.1909 98.2708 10.4138 99.102 10.5129L99.8576 10.612V10.835C99.0011 10.835 98.2708 10.8102 97.4143 10.8102C96.5579 10.8102 95.8275 10.835 94.9459 10.835V10.612L95.7015 10.5129C96.5075 10.389 96.6083 10.1661 96.6083 9.57151V6.05365H92.0238V9.57151C92.0238 10.1909 92.0994 10.4138 92.9306 10.5129L93.661 10.612V10.835C92.8046 10.835 92.0742 10.8102 91.2178 10.8102C90.3613 10.8102 89.631 10.835 88.7745 10.835V10.612L89.5301 10.5129C90.3361 10.389 90.437 10.1661 90.437 9.57151V2.46146C90.437 1.84212 90.3613 1.61915 89.5301 1.52006L88.7745 1.42096V1.198C89.631 1.198 90.3613 1.22277 91.2178 1.22277C92.0742 1.22277 92.8046 1.198 93.661 1.198V1.42096L92.9306 1.52006C92.1246 1.64393 92.0238 1.86689 92.0238 2.46146V5.75637H96.6083V2.46146Z"
        fill="#4D4D4D"
      />
      <path
        d="M108.145 11.0597C105.45 11.0597 103.233 9.0778 103.233 6.03066C103.258 2.95871 105.45 0.976807 108.145 0.976807C110.84 0.976807 113.032 2.95871 113.032 6.03066C113.032 9.0778 110.84 11.0597 108.145 11.0597ZM108.145 10.8615C110.337 10.8615 111.218 8.68142 111.218 6.03066C111.218 3.37986 110.362 1.19977 108.145 1.19977C105.878 1.19977 105.047 3.37986 105.047 6.03066C105.047 8.68142 105.903 10.8615 108.145 10.8615Z"
        fill="#4D4D4D"
      />
      <path
        d="M117.591 2.93275C117.238 1.89226 116.96 1.5702 116.306 1.49587L115.777 1.42155V1.19859C116.608 1.22336 117.439 1.22336 118.271 1.22336C119.102 1.22336 119.908 1.22336 120.764 1.19859V1.42155L119.933 1.52065C119.127 1.61975 119.026 2.09044 119.203 2.63547L121.142 8.92802L123.409 1.12427H124.165L126.784 8.92802L128.396 3.57687C128.875 2.01612 128.547 1.69406 127.616 1.52065L126.985 1.39678V1.19859C127.641 1.22336 128.27 1.22336 128.925 1.22336C129.555 1.22336 130.21 1.22336 130.84 1.19859V1.42155L130.335 1.54542C129.479 1.71884 129.152 2.18954 128.698 3.70074L127.036 9.15098L126.558 10.9347H125.827L123.157 3.15571L121.419 9.10145L120.941 10.9099H120.21L117.591 2.93275Z"
        fill="#4D4D4D"
      />
      <path
        d="M140.16 10.8356C139.354 10.8356 138.648 10.8604 137.842 10.8604V10.6374L138.674 10.5383C139.454 10.4392 139.429 10.0428 139.177 9.42347L138.522 7.73887H134.719L134.417 8.50687C133.863 9.91899 133.863 10.3401 134.845 10.5135L135.576 10.6374V10.8604C134.946 10.8604 134.467 10.8356 133.838 10.8356C133.207 10.8356 132.628 10.8604 131.999 10.8604V10.6374L132.477 10.5135C133.233 10.3153 133.535 9.91899 134.114 8.38298L136.331 2.75934L136.91 1.12427H137.666L140.814 9.10145C141.243 10.1915 141.419 10.4887 142.024 10.5631L142.527 10.6374V10.8604C141.646 10.8604 140.966 10.8356 140.16 10.8356ZM134.82 7.41678H138.396L136.608 2.78411L134.82 7.41678Z"
        fill="#4D4D4D"
      />
      <path
        d="M153.384 10.8598C152.477 10.8598 152.376 10.6616 152.049 10.0423L150.084 6.27662C149.958 6.30139 149.857 6.30139 149.756 6.30139C149.354 6.30139 148.975 6.27662 148.674 6.27662V9.59628C148.674 10.2156 148.749 10.4386 149.58 10.5377L150.336 10.6368V10.8598C149.48 10.8598 148.749 10.835 147.893 10.835C147.036 10.835 146.306 10.8598 145.424 10.8598V10.6368L146.18 10.5377C146.986 10.4138 147.087 10.1909 147.087 9.59628V2.46146C147.087 1.84212 147.011 1.61915 146.18 1.52006L145.424 1.42096V1.198H149.681C152.25 1.198 153.888 1.89166 153.888 3.72492C153.888 5.11225 152.653 5.80592 151.494 6.02888C151.671 6.25185 151.898 6.54913 152.15 6.99506L153.459 9.32379C153.913 10.1413 154.089 10.389 154.744 10.5377L155.273 10.6616V10.8846H153.384V10.8598ZM148.674 6.00411C149.076 6.02888 149.404 6.02888 149.781 6.02888C151.369 6.02888 152.175 5.38477 152.175 3.74969C152.175 1.96599 151.344 1.42096 149.681 1.42096H148.674V6.00411Z"
        fill="#4D4D4D"
      />
      <path
        d="M158.748 10.612L159.503 10.5129C160.309 10.389 160.41 10.1661 160.41 9.57151V2.46146C160.41 1.84212 160.334 1.61915 159.503 1.52006L158.748 1.42096V1.198H163.257C166.002 1.198 168.52 2.56055 168.52 6.02888C168.52 9.49721 166.002 10.8598 163.231 10.8598H158.722V10.612H158.748ZM163.231 10.5872C165.448 10.5872 166.682 9.29902 166.682 6.02888C166.682 2.7092 165.397 1.44574 163.231 1.44574H161.972V9.74494C161.972 10.3395 162.224 10.5872 162.878 10.5872H163.231Z"
        fill="#4D4D4D"
      />
    </Icon>
  );
};

<svg xmlns="http://www.w3.org/2000/svg"></svg>;
