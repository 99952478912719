import { Icon, IconProps } from '@chakra-ui/react';

export const Binance = (props: IconProps) => {
  return (
    <Icon width="122" height="28" viewBox="0 0 122 28" fill="none" {...props}>
      <path
        d="M7.57149 16.1643L12.2922 21.3864L17.0148 16.1622L19.762 19.1969L19.7601 19.1991L12.2922 27.4623L4.8243 19.2033L4.82046 19.1991L7.57149 16.1643ZM15.0778 14.0173L12.2922 17.103L9.50662 14.0237L9.50278 14.0173L9.99424 13.4736L10.2323 13.2124L12.2922 10.9337L15.0778 14.0173ZM18.95 14.0194L21.6971 10.9804L24.4424 14.0194L21.6971 17.0563L18.95 14.0194ZM0.13623 14.0194L2.88342 10.9804L5.62869 14.0194L2.88342 17.0563L0.13623 14.0194ZM7.57149 11.8724L12.2922 6.65241L17.0148 11.8766L19.7601 8.83766L12.2922 0.574463L4.8243 8.83554L7.57149 11.8724Z"
        fill="#4D4D4D"
      />
      <path
        d="M28.7261 7.09033H34.7004C36.1825 7.09033 37.3036 7.51507 38.0638 8.36453C38.6417 8.99951 38.9604 9.86809 38.945 10.7685V10.8089C38.9508 11.1741 38.9047 11.5373 38.8087 11.8877C38.7242 12.1893 38.5994 12.4738 38.442 12.7372C38.2942 12.9856 38.1157 13.2086 37.9122 13.3998C37.7125 13.5888 37.4937 13.7544 37.2614 13.8882C37.9333 14.1473 38.538 14.5848 39.0218 15.1624C39.4499 15.6997 39.663 16.443 39.663 17.3923V17.4326C39.6745 18.0251 39.5593 18.6112 39.3232 19.1443C39.0947 19.6349 38.7607 20.0553 38.3499 20.3675C37.8853 20.7116 37.3689 20.96 36.8237 21.1002C36.1767 21.2701 35.5125 21.3508 34.8482 21.3444H28.7261V7.09033ZM34.1014 12.854C34.6178 12.8816 35.1323 12.7584 35.5931 12.4972C35.9578 12.2615 36.1729 11.8134 36.1421 11.3462V11.3058C36.1613 10.8768 35.9809 10.4648 35.6641 10.2164C35.3454 9.96578 34.8847 9.84049 34.2838 9.84049H31.4886V12.854H34.1014ZM34.8559 18.5985C35.3704 18.6304 35.883 18.5008 36.3341 18.2226C36.6912 17.9699 36.8966 17.5197 36.8678 17.0503V17.01C36.8812 16.5661 36.695 16.1414 36.3706 15.8802C36.0404 15.5978 35.5067 15.4555 34.7695 15.4555H31.4886V18.5985H34.8559Z"
        fill="#4D4D4D"
      />
      <path
        d="M43.2212 7.09009H46.0567V21.3484H43.2212V7.09009Z"
        fill="#4D4D4D"
      />
      <path
        d="M50.3486 7.09009H52.9634L59.0068 15.8651V7.09009H61.8058V21.3484H59.3927L53.1515 12.2846V21.3484H50.3486V7.09009Z"
        fill="#4D4D4D"
      />
      <path
        d="M70.3896 6.98706H73.0005L78.5236 21.3473H75.5634L74.3846 18.1491H68.9344L67.7557 21.3473H64.8645L70.3896 6.98706ZM73.3729 15.3798L71.6566 10.7545L69.948 15.3798H73.3729Z"
        fill="#4D4D4D"
      />
      <path
        d="M81.5903 7.09009H84.2051L90.2446 15.8651V7.09009H93.0437V21.3484H90.6305L84.3894 12.2846V21.3484H81.5903V7.09009Z"
        fill="#4D4D4D"
      />
      <path
        d="M103.249 21.5979C102.345 21.6064 101.451 21.4132 100.615 21.0288C99.0297 20.3046 97.7742 18.9009 97.1349 17.1382C96.8009 16.225 96.6319 15.2503 96.6377 14.2649V14.2245C96.6223 12.2856 97.2981 10.4147 98.5172 9.01943C99.1161 8.33773 99.836 7.79832 100.629 7.43093C101.493 7.03593 102.418 6.83842 103.353 6.85117C103.906 6.84692 104.457 6.90213 105 7.01469C105.465 7.11238 105.92 7.26316 106.356 7.46278C106.751 7.64754 107.129 7.87902 107.479 8.1551C107.82 8.42481 108.141 8.72424 108.439 9.05129L106.636 11.3533C106.175 10.884 105.659 10.4848 105.1 10.1705C104.551 9.87952 103.95 9.73511 103.342 9.74573C102.318 9.73299 101.339 10.2087 100.654 11.0497C100.318 11.4638 100.055 11.9459 99.8783 12.4661C99.694 13.0162 99.5999 13.6002 99.6038 14.1884V14.2288C99.6018 14.8149 99.694 15.3989 99.8783 15.949C100.053 16.4714 100.314 16.9556 100.646 17.374C101.326 18.2255 102.307 18.7097 103.334 18.6991C103.981 18.7225 104.622 18.5675 105.202 18.251C105.755 17.9176 106.263 17.5077 106.72 17.0278L108.525 19.0453C108.206 19.4275 107.861 19.7822 107.494 20.1071C107.133 20.4235 106.741 20.6932 106.325 20.912C105.881 21.1435 105.415 21.3134 104.935 21.4195C104.38 21.5427 103.816 21.6022 103.249 21.5979Z"
        fill="#4D4D4D"
      />
      <path
        d="M111.872 7.09009H121.572V9.88059H114.661V12.773H120.747V15.5635H114.671V18.5579H121.668V21.3484H111.878L111.872 7.09009Z"
        fill="#4D4D4D"
      />
    </Icon>
  );
};
