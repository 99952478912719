// eslint-disable-next-line filename-rules/match
import { Web3AdvisoryPictogram } from '@r-client/dls/ui/pictograms';

import { CardButton } from '../../card-button/card-button';
import { Section } from '../section';

export const WEB3_ADVISORY = (
  <Section
    icon={Web3AdvisoryPictogram}
    title="Web3 Advisory"
    desktopColumns={4}
  >
    <CardButton
      title="Token design"
      description="Explore theoretical frameworks, engineering specs, and compliance"
      href="/advisory"
    />
    <CardButton
      title="Blockchain protocol design"
      description="Discover secure and transparent transactions"
      href="/advisory"
    />
    <CardButton
      title="Tokenization"
      description="Turn assets into blockchain-ready tokens"
      href="/advisory"
    />
    <CardButton
      title="Security tokenization"
      description="Open value within regulatory frameworks"
      href="/advisory"
    />
    <CardButton
      title="Token offerings"
      description="Allow users to own a piece of the network"
      href="/advisory"
    />
    <CardButton
      title="Token management"
      description="Manage distributions, vesting, and reporting all in one platform"
      href="/advisory"
    />
    <CardButton
      title="Business development"
      description="Engage strategic guidance to bring your project to market"
      href="/advisory"
    />
    <CardButton
      title="Growth + Marketing"
      description="Expand your project’s profile"
      href="/advisory"
    />
  </Section>
);
