import { Icon, IconProps } from '@chakra-ui/react';

export const Nea = (props: IconProps) => {
  return (
    <Icon width="74" height="28" viewBox="0 0 74 28" fill="none" {...props}>
      <path
        d="M73.1425 27.4377H61.2195L57.064 18.5117H48.8496L49.4173 16.9895H56.3633L51.9541 7.46674L54.7929 0.635194H58.731L67.7548 19.9486C69.0232 22.652 70.6298 26.3905 73.1425 27.4377ZM36.9628 26.4027V14.6636C36.9628 14.6636 39.9466 15.5647 42.9304 19.973V8.07561C39.9466 12.5569 36.9628 13.1901 36.9628 13.1901V1.64592C43.4015 1.64592 46.4698 4.76335 48.0764 7.60069L51.024 0.61084H23.856C26.3203 2.36439 28.1082 3.88657 28.1082 10.0118V27.4134H51.024L47.9677 20.9472C46.2282 23.6749 42.9183 26.4027 36.9628 26.4027ZM0.662354 0.635194C2.5106 1.70681 5.13196 3.46036 5.13196 8.91585V19.4372C5.13196 25.2823 0.928115 27.4134 0.928115 27.4134H10.6767C10.6767 27.4134 6.47284 25.2945 6.47284 19.4372V5.92021L25.9821 27.4986V13.9573L13.9141 0.635194H0.662354ZM65.0247 3.60649H64.469V1.62157H65.2301C65.5925 1.5485 65.9428 1.77987 66.0153 2.1452C66.0274 2.18173 66.0274 2.21826 66.0274 2.24262C66.0394 2.47399 65.8945 2.69318 65.665 2.77842L66.1361 3.59431H65.6046L65.1697 2.9002H65.0247V3.60649ZM65.0247 2.53488H65.2059C65.3388 2.55923 65.4596 2.47399 65.4838 2.34004C65.4838 2.32786 65.4838 2.3035 65.4838 2.29133C65.4838 2.15738 65.375 2.05995 65.2542 2.05995C65.2421 2.05995 65.218 2.05995 65.2059 2.05995H65.0247V2.53488ZM63.1765 2.64447C63.1765 1.53632 64.0704 0.635195 65.1697 0.623018C65.1817 0.623018 65.1938 0.623018 65.2059 0.623018C66.3052 0.647373 67.175 1.56068 67.1508 2.681C67.1266 3.78915 66.2206 4.6781 65.1214 4.65375C64.0462 4.64157 63.1765 3.74044 63.1765 2.64447ZM63.563 2.64447C63.563 3.56996 64.2999 4.32496 65.218 4.32496C66.1361 4.32496 66.873 3.56996 66.873 2.64447C66.873 1.71899 66.1361 0.963985 65.218 0.963985C64.2999 0.963985 63.563 1.71899 63.563 2.64447Z"
        fill="#4D4D4D"
      />
    </Icon>
  );
};
